export const quartixConstants = {
    ENGINE_TIMES_FETCH_REQUEST: "ENGINE_TIMES_FETCH_REQUEST",
    ENGINE_TIMES_FETCH_SUCCESS: "ENGINE_TIMES_FETCH_SUCCESS",
    ENGINE_TIMES_FETCH_FAILURE: "ENGINE_TIMES_FETCH_FAILURE",

    LIVE_POSITION_FETCH_REQUEST: 'LIVE_POSITION_FETCH_REQUEST',
    LIVE_POSITION_FETCH_SUCCESS: 'LIVE_POSITION_FETCH_SUCCESS',
    LIVE_POSITION_FETCH_FAILURE: 'LIVE_POSITION_FETCH_FAILURE',

    TRIP_SUMMARY_FETCH_REQUEST: 'TRIP_SUMMARY_FETCH_REQUEST',
    TRIP_SUMMARY_FETCH_SUCCESS: 'TRIP_SUMMARY_FETCH_SUCCESS',
    TRIP_SUMMARY_FETCH_FAILURE: 'TRIP_SUMMARY_FETCH_FAILURE',

    ROUTE_FETCH_REQUEST: 'ROUTE_FETCH_REQUEST',
    ROUTE_FETCH_SUCCESS: 'ROUTE_FETCH_SUCCESS',
    ROUTE_FETCH_FAILURE: 'ROUTE_FETCH_FAILURE',

    ENGINE_TIMES_CLEAR_REQUEST: "ENGINE_TIMES_CLEAR_REQUEST",

};
