import React from "react";
import {Box, Typography, Stack, Avatar, Chip} from "@mui/material";
import {EngineOnIcon} from "../../../utils/ui/Icons/EngineOnIcon.js";
import {EngineOffIcon} from "../../../utils/ui/Icons/EngineOff.js";
import "./LaveuseListPanel.css"
import {helper} from "../../../utils/helper.js";
import {NoUserIcon} from "../../../utils/ui/Icons/NoUserIcon.js";
import Button from "@mui/material/Button";
import {TravelIcon} from "../../../utils/ui/Icons/TravelIcon.js";

const statusColors = {
    1: "success",     // Disponible
    2: "info",        // En service
    3: "error"        // Panne
};

export default function LaveuseListPanel({laveuses, onSelect, selectedId, onShowTrips}) {
    // Tri : opérateur libellé A-Z, puis nulls
    const sorted = (Array.isArray(laveuses) ? [...laveuses] : []).sort((a, b) => {
        const opA = a.operateur?.libelle || "";
        const opB = b.operateur?.libelle || "";
        return opB.localeCompare(opA);
    });

    const getOperateurLibelle = (lav) => {
        if (lav?.operateur?.libelle) {
            return (
                <Typography variant="subtitle1" color="text.secondary" sx={{mt: 1}}>
                    {lav?.operateur?.libelle}
                </Typography>
            )
        } else {
            return <NoUserIcon fontSize={24} color={"grey"}/>
        }
    }

    return (
        <Box p={1} className={"laveuse-list-panel"} display="flex" flexDirection="column" gap={1} >
            {sorted?.map(lav => (
                <Box
                    key={lav.id}
                    onClick={() => onSelect?.(lav)}
                    bgcolor={
                        lav.id === selectedId
                            ? 'action.selected'
                            : helper.isLaveuseEngineOn(lav?.live?.LastEventDescription)
                                ? 'rgba(0,255,0,0.04)' // ou success.light
                                : 'background.paper'
                    }
                    p={1}
                    borderRadius={2}
                    display="flex"
                    alignItems="flex-start"
                    gap={1}
                    sx={{cursor: 'pointer', boxShadow: 1}}
                >
                    {/* Icône laveuse */}
                    <Stack direction={"column"} spacing={2}>
                        <Typography variant="subtitle2" textAlign={"center"}>{lav.description}</Typography>
                        <Box
                            component="img"
                            src="/images/mini_laveuse.webp"
                            alt="laveuse"
                            sx={{
                                width: 120,
                                height: 'auto',
                                objectFit: 'contain',
                                display: 'block',
                                pt: 0.5
                            }}
                        />
                        <Typography variant="subtitle2" textAlign={"center"}>{lav.immatriculation}</Typography>
                    </Stack>


                    <Box flexGrow={1} display="flex" flexDirection="column" width="100%">
                        <Box display="flex" justifyContent="end">
                            {/* Colonne droite : opérateur + icone moteur */}
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                {getOperateurLibelle(lav)}
                                {helper.isLaveuseEngineOff(lav?.live?.LastEventDescription) && (
                                    <EngineOffIcon color="grey" fontSize={24}/>
                                )}
                                {helper.isLaveuseEngineOn(lav?.live?.LastEventDescription) && (
                                    <EngineOnIcon color="green" fontSize={24}/>
                                )}
                            </Stack>

                        </Box>

                        {/* Ligne du bas : livetext */}
                        <Stack direction="column" spacing={1} mt={1} justifyContent={"center"}>
                            <Typography variant={"caption"}>
                                {lav?.live?.LocationText}
                            </Typography>



                            <Button
                                variant={"contained"}
                                startIcon={<TravelIcon fontSize={24} />}
                                onClick={() => onShowTrips?.(lav)}>
                                Trajets
                            </Button>


                            {/*<Chip*/}
                            {/*    size="small"*/}
                            {/*    label={lav.currentStatus?.description ?? "Inconnu"}*/}
                            {/*    color={statusColors[lav.currentStatus?.statut] || 'default'}*/}
                            {/*/>*/}
                        </Stack>
                    </Box>
                </Box>
            ))}
        </Box>
    );

}
