import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import Loader from "./layout/loading/loader";
import {Formik} from "formik";
import './UsersNewOrEdit.css'
import {date, number, object, string} from 'yup';
import {Container, Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SubmitButton from "./layout/forms/SubmitButton";
import {problemsActions} from "../../modules/problems/problems.actions";
import ProblemDataField from "./problems/form/ProblemDataField";
import {Engineering} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import Constants from "../../utils/constants";
import {prestationsActions} from "../../modules/prestations/prestations.actions.js";
import PrestationDataField from "./prestations/form/PrestationDataField.js";
import {ClockIcon} from "@mui/x-date-pickers";
import {usersActions} from "../../modules/users/users.actions.js";
import FeuilleDeTempsDataField from "./rh/form/FeuilleDeTempsDataField.js";
import {format} from "date-fns";
import {heuresActions} from "../../modules/heures/heures.actions.js";


const fdtValidationSchema = object({
    operateur: number().required('Veuillez sélectionner un opérateur'),
    heureDebut: date()
        .required("L'heure de début est requise"),
    heureFin: date()
        .required("L'heure de fin est requise")
        .test(
            'is-after-start',
            "L'heure de fin doit être postérieure à l'heure de début",
            function (value) {
                const {heureDebut} = this.parent;
                return value > heureDebut;
            }
        )
        .test(
            'within-24h',
            "L'heure de fin ne doit pas être plus de 24 heures après l'heure de début",
            function (value) {
                const { heureDebut } = this.parent;
                if (!heureDebut || !value) return true;
                const maxEnd = new Date(heureDebut.getTime() + 24 * 60 * 60 * 1000); // heure début + 24h
                return value <= maxEnd;
            }
        ),
    tempsPauseMinutes: number().required("Le temps pause est requis"),
});

function FeuilleDeTempsNew({...props}) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!props.operateurs && !props.loading && !props.error) {
            dispatch(usersActions.fetchOperateurs())
        }
    }, []);

    const handleSubmit = (values) => {
        dispatch(heuresActions.createPoste(values))
    }

    const initialValues = {
        operateur: '',
        heureDebut: format(new Date().setHours(9, 0, 0, 0), "yyyy-MM-dd HH:mm:ss"),
        heureFin: format(new Date().setHours(18, 0, 0, 0), "yyyy-MM-dd HH:mm:ss"),
        tempsPauseMinutes: 30,
        statutJournee: "WD"
    }

    if (props.loading || !props.operateurs) {
        return <Loader/>;
    }

    return (
        <>
            <PageTitle title={"Saisie des heures"} icon={<ClockIcon/>}/>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={fdtValidationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                    setTimeout(() => {
                        setSubmitting(false)
                    }, 2000);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <FeuilleDeTempsDataField operateurs={props.operateurs}
                                                 values={values}
                                                 errors={errors}
                                                 handleChange={handleChange}
                                                 handleBlur={handleBlur}
                        />

                        <Grid container justifyContent="center" style={{marginTop: '2rem'}}>
                            <Grid item xs={4}>
                                <SubmitButton submitting={isSubmitting && !props.error}/>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        operateurs: state.users.operateurs,
        loading: state.users.loading,
        error: state.users.error
    }
}

export default connect(mapStateToProps)(FeuilleDeTempsNew);
