import React from 'react';
import './Footer.css';
import {useNavigate} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Avatar,
    Box, Chip, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton, MenuItem, Stack,
    Typography
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {useLocation} from 'react-router-dom';
import {helper} from "../../../utils/helper";
import Constants from "../../../utils/constants";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import {authActions} from "../../../modules/authentication/auth.actions";
import {useDispatch} from "react-redux";
import {MenuOpen, Menu} from "@mui/icons-material";
import Account from "./account/Account.js";
import packageEnv from "../../../../package.json";

function ContentHeader({drawerOpen, setDrawerOpen}) {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const userMenuOpen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const currentUser = helper.getCurrentUser();

    const isHomePage = location.pathname === '/';

    const userHasLogo = () => {
        return currentUser?.logo?.hasOwnProperty("filePath");
    }

    const openLogoutModal = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    }
    const logout = () => {
        dispatch(authActions.logout()).then(() => {
            navigate(Constants.APP_ROUTE_LOGIN)
        })
    }

    const isBeta = () => {
        return import.meta.env.VITE_ENV === "beta"
    }

    const getAppVersion = () => {
        let appVersion = `v${packageEnv.version}`;
        if (import.meta.env.VITE_ENV === "beta") {
            appVersion += "-BETA";
        }
        return appVersion;
    };

    return (
        <>
            <Stack direction={"row"} spacing={2} className={"content-header"} useFlexGap alignItems={"center"}>
                {drawerOpen &&
                    <IconButton size={"large"} onClick={() => {
                        setDrawerOpen(!drawerOpen)
                    }}>
                        <MenuOpen fontSize={"inherit"} sx={{color: "white"}}/>
                    </IconButton>
                }
                {!drawerOpen &&
                    <IconButton size={"large"} onClick={() => {
                        setDrawerOpen(!drawerOpen)
                    }}>
                        <Menu fontSize={"inherit"} sx={{color: "white"}}/>
                    </IconButton>
                }
                <img
                    className={"content-header-brand"}
                    src={"/images/logo_marquedeposee_blanc_vert_small.png"}
                    onClick={() => navigate(Constants.APP_ROUTE_HOME)}
                    alt="Logo"
                />
                {/*{isBeta() ? <Chip label={getAppVersion()} sx={{backgroundColor: "firebrick"}}/> :*/}
                {/*    <Typography variant={"caption"}>{getAppVersion()}</Typography>*/}

                {/*}*/}


                <Stack direction={"row"} sx={{width: "100%", justifyContent: "flex-end"}}>
                    <Account onLogout={openLogoutModal}/>
                </Stack>
            </Stack>
            <Dialog
                className={"modalLogout"}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{minWidth: "450px"}}>
                    Déconnexion
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vous allez être déconnecté, continuer ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={"error"} variant={"contained"}>Annuler</Button>
                    <Button onClick={logout} color={"success"} variant={"contained"}>
                        Me déconnecter
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

    // return (
    //     <Grid container className={"content-header"}>
    //         <Grid size={10}>
    //
    //             <Button
    //                 sx={{background: "white"}}
    //                 variant={"outlined"}
    //                 startIcon={drawerOpen ? <MenuOpen/> : <Menu/>}
    //             >
    //
    //             </Button>
    //             <img
    //                 className={"content-header-brand"}
    //                 src={"/images/logo_marquedeposee_blanc_vert_small.png"}
    //                 onClick={() => navigate(Constants.APP_ROUTE_HOME)}
    //                 alt="Logo"
    //             />
    //             {!isHomePage &&
    //                 <Box display="flex" alignItems="center" sx={{cursor: 'pointer'}} onClick={() => {
    //                     navigate(-1)
    //                 }}>
    //                     <ArrowBackIcon/>
    //                     <Box ml={1} className={"navigation-back-label"}>Retour à la page précédente</Box>
    //                 </Box>
    //             }
    //         </Grid>
    //
    //         <Grid size={2} container justifyContent={"end"} flexDirection={"row"}>
    //             <Button
    //                 sx={{padding: 0}}
    //                 aria-controls={open ? 'basic-menu' : undefined}
    //                 aria-haspopup="true"
    //                 aria-expanded={open ? 'true' : undefined}
    //                 onClick={handleClick}>
    //                 {userHasLogo() &&
    //                     <img src={Constants.API_ASSETS_USER_LOGO + "/users/logo/" + currentUser?.logo?.filePath}
    //                          className={"content-header-logo"}/>
    //                     // <Avatar src={Constants.API_ASSETS_USER_LOGO + "/users/logo/" + currentUser.logo?.filePath} variant={"rounded"} sx={{ width: 64, height: 64 }}/>
    //                 }
    //                 {!userHasLogo() &&
    //                     <Avatar></Avatar>
    //                 }
    //             </Button>
    //             <Button variant="filled" color="primary" className="logout-button"
    //                     endIcon={<LogoutIcon/>} onClick={openLogoutModal}>
    //             </Button>
    //             <Menu
    //                 open={userMenuOpen}
    //                 anchorEl={anchorEl}
    //                 onClose={handleCloseUserMenu}
    //             >
    //                 <MenuItem onClick={handleCloseUserMenu}><Typography
    //                     variant={"body2"}>{currentUser?.libelle}</Typography></MenuItem>
    //                 <MenuItem onClick={handleCloseUserMenu}><Typography
    //                     variant={"caption"}>{currentUser?.email}</Typography></MenuItem>
    //                 <MenuItem>
    //                     <Button variant="outlined" color="primary" fullWidth className="logout-button"
    //                             endIcon={<LogoutIcon/>} onClick={openLogoutModal}>
    //                         Déconnexion
    //                     </Button>
    //                 </MenuItem>
    //             </Menu>
    //         </Grid>
    //         <Dialog
    //             className={"modalLogout"}
    //             open={open}
    //             onClose={handleClose}
    //             aria-labelledby="alert-dialog-title"
    //             aria-describedby="alert-dialog-description"
    //         >
    //             <DialogTitle id="alert-dialog-title" sx={{minWidth: "450px"}}>
    //                 Déconnexion
    //             </DialogTitle>
    //             <DialogContent>
    //                 <DialogContentText>
    //                     Vous allez être déconnecté, continuer ?
    //                 </DialogContentText>
    //             </DialogContent>
    //             <DialogActions>
    //                 <Button onClick={handleClose} color={"error"} variant={"contained"}>Annuler</Button>
    //                 <Button onClick={logout} color={"success"} variant={"contained"}>
    //                     Me déconnecter
    //                 </Button>
    //             </DialogActions>
    //         </Dialog>
    //     </Grid>
    // );
}

export default ContentHeader;
