import React from 'react';
import Grid from "@mui/material/Grid2";
import {isExploitant} from "../../utils/roles";
import DashboardView from "./dashboard/DashboardView";
import DashboardViewV2 from "./dashboard/DashboardViewV2.js";
import {ButtonGroup, IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import {FormatListBulleted, Tablet} from "@mui/icons-material";

const DASHBOARD_MODE_OLD = "OLD"
const DASHBOARD_MODE_NEW = "NEW"

const getInitialMode = () => {
    return window.localStorage.getItem("dashboard_view_mode") ?? DASHBOARD_MODE_NEW;
}

const DashboardPage = () => {
    const [mode, setMode] = React.useState(getInitialMode());

    const changeMode = (mode) => {
        window.localStorage.setItem("dashboard_view_mode", mode);
        setMode(mode);
    }

    return (
        <>
            <Grid container>
                {isExploitant() &&
                    <Grid item size={12}>
                        {/*<ButtonGroup>*/}
                        {/*    <Button*/}
                        {/*        variant={mode === DASHBOARD_MODE_NEW ? "contained" : "oulined"}*/}
                        {/*        startIcon={<Tablet/>}*/}
                        {/*        onClick={() => changeMode(DASHBOARD_MODE_NEW)}*/}
                        {/*    />*/}
                        {/*    <Button*/}
                        {/*        variant={mode === DASHBOARD_MODE_OLD ? "contained" : "oulined"}*/}
                        {/*        startIcon={<FormatListBulleted/>}*/}
                        {/*        onClick={() => changeMode(DASHBOARD_MODE_OLD)}*/}
                        {/*    />*/}
                        {/*</ButtonGroup>*/}
                        {mode === DASHBOARD_MODE_NEW &&
                            <DashboardViewV2 onModeChange={changeMode}/>
                        }
                        {mode === DASHBOARD_MODE_OLD &&
                            <DashboardView onModeChange={changeMode}/>
                        }
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default DashboardPage;
