import {laveusesConstants} from "./laveuses.constants";
import {laveusesService} from "./laveuses.service";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";

export const laveusesActions = {
    fetchLaveuses,
    fetchLaveuse,
    deleteLaveuse,
    createLaveuse,
    updateLaveuse,
    fetchDailyContext,
    clearLaveuses
};

function fetchLaveuses(pagination = false) {
    return dispatch => {
        dispatch(request());

        laveusesService.fetchLaveuses(pagination)
            .then(
                laveuses => {
                    dispatch(success(laveuses));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: laveusesConstants.LAVEUSES_FETCH_REQUEST}
    }

    function success(laveuses) {
        return {type: laveusesConstants.LAVEUSES_FETCH_SUCCESS, laveuses}
    }

    function failure(error) {
        return {type: laveusesConstants.LAVEUSES_FETCH_FAILURE, error}
    }
}

function fetchLaveuse(id, serializationgroup = null) {
    return dispatch => {
        dispatch(request());
        laveusesService.fetchLaveuse(id, serializationgroup)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: laveusesConstants.LAVEUSES_FETCH_ONE_REQUEST}
    }

    function success(laveuse) {
        return {type: laveusesConstants.LAVEUSES_FETCH_ONE_SUCCESS, laveuse}
    }

    function failure(error) {
        return {type: laveusesConstants.LAVEUSES_FETCH_ONE_FAILURE, error}
    }
}

function deleteLaveuse(id, navigate) {
    return dispatch => {
        dispatch(request());

        laveusesService.deleteLaveuse(id)
            .then(
                id => {
                    dispatch(success(id));
                    history.replace(Constants.APP_ROUTE_ADMIN_LAVEUSES);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };


    function request() {
        return {type: laveusesConstants.LAVEUSES_DELETE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le laveuse a bien été supprimé.")
        return {type: laveusesConstants.LAVEUSES_DELETE_SUCCESS, id}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: laveusesConstants.LAVEUSES_DELETE_FAILURE, error}
    }
}

function createLaveuse(values, navigate) {
    return dispatch => {
        dispatch(request());

        laveusesService.createLaveuse(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_LAVEUSES);
                },
                error => {
                    if (error === '') {
                        error = "Erreur lors de la création"
                    }
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: laveusesConstants.LAVEUSES_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La laveuse a bien été créé.")
        return {type: laveusesConstants.LAVEUSES_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: laveusesConstants.LAVEUSES_CREATE_FAILURE, error}
    }
}

function fetchDailyContext(date, vehicleId) {
    return dispatch => {
        dispatch(request());

        laveusesService.fetchDailyContext(date, vehicleId)
            .then(
                (data) => {
                    dispatch(success(data));
                },
                error => {
                    if (error === '') {
                        error = "Erreur lors de la création"
                    }
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: laveusesConstants.FETCH_LAVEUSE_CONTEXT_REQUEST}
    }

    function success(data) {
        return {type: laveusesConstants.FETCH_LAVEUSE_CONTEXT_SUCCESS, data}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: laveusesConstants.FETCH_LAVEUSE_CONTEXT_FAILURE, error}
    }
}

function updateLaveuse(values, updateStatutDate = false) {
    return dispatch => {
        dispatch(request());
        if (updateStatutDate) {
            values.statutUpdatedAt = new Date().toISOString();
        }

        laveusesService.updateLaveuse(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_LAVEUSES);
                },
                error => {
                    if (error === '') {
                        error = "Erreur lors de la mise à jour"
                    }
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: laveusesConstants.LAVEUSES_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La laveuse a bien été modifié.")
        return {type: laveusesConstants.LAVEUSES_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: laveusesConstants.LAVEUSES_UPDATE_FAILURE, error}
    }
}

function clearLaveuses() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {type: laveusesConstants.LAVEUSES_CLEAR_REQUEST}
    }
}
