import {campaignsConstants} from './campaigns.constants';
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {
    campaigns: null,
    campaign: null,
    details: null,
    detailsLoading: false,
    detailsError: null,
    loading: false,
    error: null
}

export function campaigns(state = initialState, action) {
    switch (action.type) {
        case campaignsConstants.CAMPAIGNS_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case campaignsConstants.CAMPAIGNS_FETCH_SUCCESS:
            return {
                ...state,
                campaign: null,
                campaigns: action.campaigns,
                loading: false,
                error: null
            };
        case campaignsConstants.CAMPAIGNS_FETCH_ONE_REQUEST:
            return {
                ...state,
                campaign: null,
                loading: true,
                error: null
            };
        case campaignsConstants.CAMPAIGNS_FETCH_ONE_SUCCESS:
            return {
                ...state,
                campaign: action.campaign,
                loading: false,
                error: null
            };
        case campaignsConstants.CAMPAIGNS_FETCH_ONE_FAILURE:
            return {
                ...state,
                campaign: null,
                loading: false,
                error: action.error
            };
        case campaignsConstants.CAMPAIGNS_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case campaignsConstants.CAMPAIGNS_DELETE_SUCCESS:
        case campaignsConstants.CAMPAIGNS_CREATE_SUCCESS:
        case campaignsConstants.CAMPAIGNS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                campaigns: null,
                campaign: null,
            };
        case campaignsConstants.CAMPAIGNS_FETCH_FAILURE:
        case campaignsConstants.CAMPAIGNS_CREATE_FAILURE:
        case campaignsConstants.CAMPAIGNS_UPDATE_FAILURE:
        case campaignsConstants.CAMPAIGNS_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case campaignsConstants.CAMPAIGNS_FETCH_DETAILS_REQUEST:
            return {
                ...state,
                detailsLoading: true,
                detailsError: null,
                details: null
            };
        case campaignsConstants.CAMPAIGNS_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                detailsLoading: false,
                details: action.data
            };
        case campaignsConstants.CAMPAIGNS_FETCH_DETAILS_FAILURE:
            return {
                ...state,
                detailsLoading: false,
                detailsError: action.error
            };
        case campaignsConstants.CAMPAIGNS_CLEAR_REQUEST:
            return {
                ...initialState
            }
        case authenticationConstants.LOGOUT:
            return {
                initialState
            }
        default:
            return state
    }
}
