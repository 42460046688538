import React from 'react';
import {helper} from "../../../../utils/helper.js";
import {Avatar, IconButton, Stack, Typography} from "@mui/material";
import './Account.css'
import {MoreVert, Settings} from "@mui/icons-material";
import Constants from "../../../../utils/constants.js";
import LogoutIcon from "@mui/icons-material/Logout";

function Account({onLogout, ...props}) {

    const userHasLogo = () => {
        return currentUser?.logo?.hasOwnProperty("filePath");
    }

    const userLogo = () => {
        return Constants.API_ASSETS_USER_LOGO + "/users/logo/" + currentUser?.logo?.filePath
    }

    const currentUser = helper.getCurrentUser();

    return (
        <>
            {currentUser &&
                <Stack direction="row" spacing={2} className="account-wrapper">
                    {!userHasLogo() &&
                        <Avatar src={userLogo()} alt={currentUser.libelle}/>
                    }
                    {userHasLogo() &&
                        <Avatar>{currentUser.libelle.charAt(0)}</Avatar>
                    }

                    <Stack spacing={0}>
                        <Typography variant={"subtitle1"} sx={{color:'white'}}>{currentUser.libelle}</Typography>
                        <Typography variant={"subtitle2"} sx={{color:'white'}}>{currentUser.email}</Typography>
                    </Stack>

                    <IconButton size={"large"} onClick={onLogout}>
                        <LogoutIcon fontSize={"inherit"} sx={{color: "white"}}/>
                    </IconButton>
                </Stack>
            }
        </>

    )
}

export default Account;
