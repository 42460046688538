import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import Constants from "../../utils/constants";
import {laveusesActions} from "../../modules/laveuses/laveuses.actions";
import {LocalShipping} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import Grid from "@mui/material/Grid2";
import LaveuseMap from "./laveuse/LaveuseMap.js";
import LaveuseListPanel from "./laveuse/LaveuseListPanel.js";
import {useNavigate} from "react-router-dom";

const getMode = () => {
    const storedMode = window.localStorage.getItem("laveuse_view_mode");
    return storedMode || Constants.MODE_LIST;
};

function LaveuseTrackingPage({dispatch, ...props}) {
    const [selectedLaveuse, setSelectedLaveuse] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!props.laveuses && !props.loading && !props.error) {
            dispatch(laveusesActions.fetchLaveuses())
        }
    }, [props.laveuses, props.loading, props.error]);

    useEffect(() => {
        return () => {
            // dispatch(conteneursActions.clearLaveuses());
        };
    }, [dispatch]);

    const onLaveuseSelected = (lav) => {
        if (lav) {
            setSelectedLaveuse(lav);
        }
    }

    const handleShowTrips = (lav) => {
        // ICI on va navigate en passant l'id de la laveuse dans la route
        navigate(Constants.APP_ROUTE_LAVEUSES_TRIPS_PAGE.replace(":id", lav?.id))
    }

    return (
        <>
            {props.loading &&
                <Loader/>
            }
            {!props.loading &&
                <>
                    {/* ICI 1/4 une stack vertical des props.laveuse style quartix*/}
                    {/* ET 3/4 ce composnant :   */}
                    <Grid container>
                        <Grid size={4}>
                            <LaveuseListPanel
                                laveuses={props.laveuses}
                                onSelect={onLaveuseSelected}
                                selectedId={selectedLaveuse?.id}
                                onShowTrips={handleShowTrips}
                            />
                        </Grid>
                        <Grid item size={8}>
                            <LaveuseMap laveuses={props.laveuses} selectedLaveuse={selectedLaveuse}/>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        laveuses: state.laveuses.laveuses,
        loading: state.laveuses.loading,
        error: state.laveuses.error
    }
}

export default connect(mapStateToProps)(LaveuseTrackingPage);
