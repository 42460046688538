import {dashboardConstants} from './dashboard.constants';

const initialState = {data: null, datav2: null, loading: false, error: null}

export function dashboard(state = initialState, action) {
    switch (action.type) {
        case dashboardConstants.DASHBOARD_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case dashboardConstants.DASHBOARD_FETCH_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
                error: null
            };

        case dashboardConstants.DASHBOARD_FETCH_V2_SUCCESS:
            return {
                ...state,
                datav2: action.data,
                loading: false,
                error: null
            };
        case dashboardConstants.DASHBOARD_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state
    }
}
