const APIURL = import.meta.env.VITE_APIURL
const API_ASSETS_URL = import.meta.env.VITE_APIASSETSURL

const Constants = {
    /** API ROUTE */
    API_ROUTE_LOGIN: APIURL + "/login_check",
    API_ROUTE_TOKEN_REFRESH: APIURL + "/refresh",
    API_ROUTE_ASK_RESET_PASSWORD: APIURL + "/users/ask-reset-password ",
    API_ROUTE_RESET_PASSWORD: APIURL + "/users/reset-password",
    API_ROUTE_USERS: APIURL + "/users",
    API_ROUTE_USER: APIURL + "/users/:id",
    API_ROUTE_EXPLOITANTS: APIURL + "/exploitants",
    API_ROUTE_EXPLOITANT: APIURL + "/exploitants/:id",
    API_ROUTE_ADMINS: APIURL + "/admins",
    API_ROUTE_ADMIN: APIURL + "/admins/:id",
    API_ROUTE_CLIENTS: APIURL + "/clients",
    API_ROUTE_CLIENT: APIURL + "/clients/:id",
    API_ROUTE_OPERATEURS: APIURL + "/operateurs",
    API_ROUTE_OPERATEUR: APIURL + "/operateurs/:id",
    API_ROUTE_USERS_LOGO: APIURL + "/user_logos",
    API_ROUTE_PATRIMOINES: APIURL + "/patrimoines",
    API_ROUTE_PATRIMOINE: APIURL + "/patrimoines/:id",
    API_ROUTE_PATRIMOINES_CLIENT: APIURL + "/patrimoines?client=:id",
    API_ROUTE_PATRIMOINE_INTERVENTIONS: APIURL + "/patrimoines/:id?groups=patrimoineintervention",
    API_ROUTE_PROBLEMS: APIURL + '/problems',
    API_ROUTE_PROBLEM: APIURL + '/problems/:id',
    API_ROUTE_PROBLEMS_LAVEUSE: APIURL + '/problem_laveuses',
    API_ROUTE_PROBLEM_LAVEUSE: APIURL + '/problem_laveuses/:id',
    API_ROUTE_PRESTATIONS: APIURL + '/prestations',
    API_ROUTE_PRESTATION: APIURL + '/prestations/:id',
    API_ROUTE_FORMS: APIURL + '/forms',
    API_ROUTE_FORM: APIURL + '/forms/:id',
    API_ROUTE_LAVEUSES: APIURL + '/laveuses',
    API_ROUTE_LAVEUSE: APIURL + '/laveuses/:id',
    API_ROUTE_LAVEUSE_DAILY_CONTEXT: APIURL + '/laveuse/daily-context?date=:date&vehicleId=:id',
    API_ROUTE_CONTENEURS: APIURL + '/conteneurs',
    API_ROUTE_CONTENEUR: APIURL + '/conteneurs/:id',
    API_ROUTE_INTERVENTIONS: APIURL + '/interventions',
    API_ROUTE_INTERVENTIONS_BY_CONTAINERID: APIURL + '/interventions?containerId=:id',
    API_ROUTE_INTERVENTIONS_BY_PATRIMOINEID: APIURL + '/patrimoine/:id/interventions',
    API_ROUTE_INTERVENTION: APIURL + '/interventions/:id',
    API_ROUTE_CAMPAIGNS: APIURL + '/campagne_lavages',
    API_ROUTE_CAMPAIGN: APIURL + '/campagne_lavages/:id',
    API_ROUTE_CAMPAIGN_DETAILS_CONTENEURS_INTERVENTIONS: APIURL + '/tournee/conteneurs-interventions?date=:date&tourneeId=:id',
    API_ROUTE_SYNTHESE: APIURL + '/synthese',
    API_ROUTE_SYNTHESE_INTERVENTION: APIURL + '/synthese/intervention/:id',
    API_ROUTE_SYNTHESE_INTERVENTION_TOKEN: "95735835-5def-47f5-94bf-912e8d4620f2",
    API_ROUTE_DASHBOARD_DATA: APIURL + '/dashboard',
    API_ROUTE_DASHBOARD_DATA_V2: APIURL + '/newdashboard',
    API_ROUTE_RHDATAS: APIURL + '/r_h_datas',
    API_ROUTE_RHDATA: APIURL + '/r_h_datas/:id',
    API_ROUTE_FEUILLEDETEMPS: APIURL + '/feuille_de_temps',
    API_ROUTE_FEUILLEDETEMP: APIURL + '/feuille_de_temps/:id',
    API_ROUTE_PERIODE_PAIES: APIURL + '/periode_paies',
    API_ROUTE_PERIODE_PAIE: APIURL + '/periode_paies/:id',
    API_ROUTE_RH_SUIVI_HEURES: APIURL + '/suivi-heures?startDate=:startDate&endDate=:endDate',
    API_ROUTE_EXPORT_SUIVI_HEURES: APIURL + '/suivi-heures/export',
    API_ROUTE_RH_FICHE_CHANTIER: APIURL + '/fiche-chantier?startDate=:startDate&endDate=:endDate&operateurId=:operateurId',
    API_ROUTE_RH_CREATE_FDT: APIURL + "/suivi-heures/create",
    API_ROUTE_QUARTIX_ENGINE_TIMES: APIURL + '/quartix/vehicle-engine-times?date=:date&vehicleId=:vehicleId',
    API_ROUTE_QUARTIX_TRIP_SUMMARY: APIURL + '/quartix/vehicle-trip-summary?date=:date&vehicleId=:vehicleId',
    API_ROUTE_QUARTIX_ROUTE: APIURL + '/quartix/vehicle-route?date=:date&vehicleId=:vehicleId',

    API_ASSETS_USER_LOGO: API_ASSETS_URL,
    API_ASSETS_PDF: API_ASSETS_URL,
    API_ASSETS_PHOTO_INTERVENTION: API_ASSETS_URL,


    /** APP ROUTE */
    APP_ROUTE_LOGIN: "/login",
    APP_ROUTE_FORGOT_PASSWORD: "/forgotPassword",
    APP_ROUTE_RESET_PASSWORD: "/resetPassword/:token",
    APP_ROUTE_HOME: "/",
    APP_ROUTE_ADMIN_USERS: "/admin/users",
    APP_ROUTE_ADMIN_USERS_NEW: "/admin/users/new",
    APP_ROUTE_ADMIN_USERS_EDIT: "/admin/users/:type/:id/edit",
    APP_ROUTE_PATRIMOINE: "/patrimoine",
    APP_ROUTE_PATRIMOINE_NEW: "/patrimoine/:id/new/",
    APP_ROUTE_PATRIMOINE_EDIT: "/patrimoine/:patrimoineId/edit",
    APP_ROUTE_PATRIMOINE_IMPORT: "/patrimoine/:id/import/",
    APP_ROUTE_PATRIMOINE_DETAIL: "/patrimoine/:id/detail/",
    APP_ROUTE_INTERVENTIONS: "/conteneurs/:id/interventions",
    APP_ROUTE_INTERVENTIONS_UNIPATRIMOINE: "/patrimoine/:id/interventions",
    APP_ROUTE_ADMIN_PROBLEMS: "/admin/problems",
    APP_ROUTE_ADMIN_PROBLEMS_NEW: "/admin/problems/new",
    APP_ROUTE_ADMIN_PROBLEMS_EDIT: "/admin/problems/:id/edit",
    APP_ROUTE_ADMIN_PROBLEMS_LAVEUSES_NEW: "/admin/problemsLaveuses/new",
    APP_ROUTE_ADMIN_PROBLEMS_LAVEUSES_EDIT: "/admin/problemsLaveuses/:id/edit",
    APP_ROUTE_ADMIN_PRESTATIONS: "/admin/prestations",
    APP_ROUTE_ADMIN_PRESTATIONS_NEW: "/admin/prestations/new",
    APP_ROUTE_ADMIN_PRESTATIONS_EDIT: "/admin/prestations/:id/edit",
    APP_ROUTE_ADMIN_FORMS: "/admin/forms",
    APP_ROUTE_ADMIN_FORMS_NEW: "/admin/forms/new",
    APP_ROUTE_ADMIN_FORMS_EDIT: "/admin/forms/:id/edit",
    APP_ROUTE_ADMIN_LAVEUSES: "/admin/laveuses",
    APP_ROUTE_LAVEUSES_TRACKING_PAGE: "/laveuses-tracking",
    APP_ROUTE_LAVEUSES_TRIPS_PAGE: "/laveuses/:id/trips",
    APP_ROUTE_ADMIN_LAVEUSES_NEW: "/admin/laveuses/new",
    APP_ROUTE_ADMIN_LAVEUSES_EDIT: "/admin/laveuses/:id/edit",
    APP_ROUTE_ADMIN_LAVEUSES_JOURNAL: "/admin/laveuses/:id/journal",
    APP_ROUTE_ADMIN_CAMPAIGNS: "/admin/tournees",
    APP_ROUTE_ADMIN_CAMPAIGNS_NEW: "/admin/tournees/new",
    APP_ROUTE_ADMIN_CAMPAIGNS_EDIT: "/admin/tournees/:id/edit",
    APP_ROUTE_ADMIN_CAMPAIGNS_UPDATE: "/admin/tournees/:id/update",
    APP_ROUTE_ADMIN_INTERVENTIONS_FORM: "/admin/intervention/form",
    APP_ROUTE_ADMIN_RH_DATA: "/admin/rhdata",
    APP_ROUTE_ADMIN_RH_DATA_EDIT: "/admin/rhdata/:id/edit",
    APP_ROUTE_RH_SUIVIHEURES: "/RH/suivi-heures",
    APP_ROUTE_RH_FICHECHANTIER: "/RH/fiche-chantier",
    APP_ROUTE_RH_PERIODE_PAIES: "/RH/periode-paies",
    APP_ROUTE_RH_PERIODE_PAIES_NEW: "/RH/periode-paies/new",
    APP_ROUTE_RH_PERIODE_PAIES_EDIT: "/RH/periode-paies/:id/edit",
    APP_ROUTE_RH_FDT_NEW: "/RH/heures/new",
    APP_ROUTE_SYNTHESE: "/synthese",
    APP_ROUTE_DASHBOARD: "/dashboard",
    APP_ROUTE_PLANNING_GSHEET: "/planning",

    /** DIVERS */
    MAX_FILESIZE_UPLOAD: 2 * 100000, // 2 MO
    GOOGLEMAPS_API_KEY: 'AIzaSyCGTI7RjTZ50rdshWTSgCAlZo9inXLCb9w',

    /** DATA EN DUR */
    CONTENEURS_TYPE: [
        {code: "CA", libelle: "Colonne Aérienne"},
        {code: "CE", libelle: "Colonne Enterrée"},
        {code: "CSE", libelle: "Colonne Semi-enterrée"},
        {code: "AB", libelle: "Abri-bac"},
        {code: "BAC", libelle: "Bac"},
        {code: "AUT", libelle: "Autre"},
    ],
    PROBLEMS_IMPORTANCE: [
        {code: 1, libelle: "Bas", color: "#7bb421"},
        {code: 2, libelle: "Moyen", color: "#f1c40f"},
        {code: 3, libelle: "Haut", color: "#e67e22"},
        {code: 4, libelle: "Critique", color: "#B22222"},
    ],

    PROBLEMS_LAVEUSE_IMPORTANCE: [
        {code: 3, libelle: "Maintenance", color: "#e67e22"},
        {code: 4, libelle: "Critique", color: "#B22222"},
    ],

    LAVEUSE_PROBLEMS_CATEGORIES: [
        {code: "machine", libelle: "Machine"},
        {code: "vehicule", libelle: "Véhicule"},
    ],

    LAVEUSE_STATUT: [
        {code: 1, libelle: "Disponible", color: "#7bb421"},
        {code: 3, libelle: "Maintenance nécessaire", color: "#e67e22"},
        {code: 4, libelle: "En panne", color: "#B22222"},
    ],

    CONTENEURS_STATUS_TODO: 1,
    CONTENEURS_STATUS_DONE: 0,
    CONTENEURS_STATUS_INACCESSIBLE: 3,
    CONTENEURS_STATUS_PROBLEME: 2,

    /** MAP **/
    MAP_RADIUS: 0.0003,
    DEGREES_PER_RADIAN: 180 / Math.PI,
    HERE_MAPS_APIKEY: "MuhS6u_L01hoP99vEg4KOZPmqfatW-t9GTPZOT_WHnQ",
    MODE_MAP: "map",
    MODE_LIST: "list",
    MAP_TILE_HERE: "https://{s}.{base}.maps.ls.hereapi.com/maptile/2.1/{type}/{mapID}/{variant}/{z}/{x}/{y}/256/{format}?apiKey={apiKey}&lg={language}",
    MAP_TILE_OSM: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",

    /** CSV MISC **/
    EXPECTED_PATRIMOINE_HEADER: ["Nomenclature", "Adresse", "Complement d'adresse", "Commune", "Type de conteneur", "Marque", "Quantite", "Type de dechet", "Volume", "Latitude", "Longitude"],
    EXPECTED_UPDATE_TOURNEE_HEADER: ["Nomenclature", "Adresse"],

    /** SYNTHESE **/
    BILAN_TYPE_GLOBAL: "global",
    BILAN_TYPE_BY_PATRIMOINE: "patrimoine",

    /** TYPOLOGIE LAVAGE **/
    TYPOLOGIE_LAVAGE_AB_PLUS_B: "ABRI BAC + BAC",
    TYPOLOGIE_LAVAGE_AB: "ABRI BAC",
    TYPOLOGIE_LAVAGE_B: "BAC",
    TYPOLOGIE_LAVAGE_C: "COLONNE",

    /** PROBLEM CATEGORY */
    PROBLEM_CATEGORY_CONTAINER: "container",
    PROBLEM_CATEGORY_LAVEUSE: "laveuse",

    /** COLORS **/
    COLOR_THEME_BLUE: "#213067",
    COLOR_THEME_GREEN: "#7bb421",
    COLOR_THEME_ORANGE: "#e67e22",
    COLOR_THEME_RED: "#B22222",

    /** HEURES **/
    TEMPS_PAUSE_MINUTES_DATA: [
        {label: "Pas de pause", value: 0},
        {label: "5 minutes", value: 5},
        {label: "15 minutes", value: 15},
        {label: "30 minutes", value: 30},
        {label: "45 minutes", value: 45},
        {label: "1 heure", value: 60},
        {label: "1 heure 15 minutes", value: 75},
        {label: "1 heure 30 minutes", value: 90},
        {label: "1 heure 45 minutes", value: 105},
        {label: "2 heures", value: 120},
        {label: "2 heures 15 minutes", value: 135},
        {label: "2 heures 30 minutes", value: 150},
        {label: "2 heures 45 minutes", value: 165},
        {label: "3 heures", value: 180},
        {label: "3 heures 15 minutes", value: 195},
        {label: "3 heures 30 minutes", value: 210},
        {label: "3 heures 45 minutes", value: 225},
        {label: "4 heures", value: 240},
    ],

    RH_STATUT_JOURNEE_DATA: [
        {id: "WD", label: "Travail", color: "#7bb421", fontColor: "#000"},
        {id: "AM", label: "Arrêt Maladie", color: "#213067", fontColor: "#FFF"},
        {id: "CP", label: "Congé Payé", color: "#6f42c1", fontColor: "#FFF"},
        {id: "RTT", label: "RTT", color: "#f1c40f", fontColor: "#000"},
    ],

    LAVEUSE_ENGINE_ON_KEYWORDS: [
        "Ignition-On",
        "moteur tournant",
        "Distance",
        "En déplacement",
        "Départ",
        "Mouvement détecté"
    ],

    LAVEUSE_ENGINE_OFF_KEYWORDS: [
        "Ignition-Off",
        "arrêt moteur",
        "Stationnaire arrêt moteur",
        "Mouvement inactif",
        "Stop"
    ],
}

export default Constants;
