import {quartixConstants} from './quartix.constants.js';
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {
    data: {},
    liveData: {},
    tripSummary: {},
    routeData: {},
    loading: false,
    error: null
}

export function quartix(state = initialState, action) {
    switch (action.type) {
        case quartixConstants.ENGINE_TIMES_FETCH_REQUEST:
        case quartixConstants.LIVE_POSITION_FETCH_REQUEST:
        case quartixConstants.TRIP_SUMMARY_FETCH_REQUEST:
        case quartixConstants.ROUTE_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case quartixConstants.ENGINE_TIMES_FETCH_SUCCESS:
            const {vehicleId, date, engineTimes} = action.payload;
            return {
                ...state,
                loading: false,
                error: null,
                data: {
                    ...state.data,
                    [vehicleId]: {
                        ...(state.data[vehicleId] || {}),
                        [date]: engineTimes,
                    },
                },
            };

        case quartixConstants.LIVE_POSITION_FETCH_SUCCESS:
            return {
                ...state,
                liveData: action.payload.data,
                loading: false,
                error: null
            };

        case quartixConstants.TRIP_SUMMARY_FETCH_SUCCESS:
            return {
                ...state,
                tripSummary: {
                    ...state.tripSummary,
                    [action.payload.vehicleId]: {
                        ...(state.tripSummary[action.payload.vehicleId] || {}),
                        [action.payload.date]: action.payload.data
                    }
                },
                loading: false,
                error: null
            };

        case quartixConstants.ROUTE_FETCH_SUCCESS:
            return {
                ...state,
                routeData: {
                    ...state.routeData,
                    [action.payload.vehicleId]: {
                        ...(state.routeData[action.payload.vehicleId] || {}),
                        [action.payload.date]: action.payload.data
                    }
                },
                loading: false,
                error: null
            };
        case quartixConstants.ENGINE_TIMES_FETCH_FAILURE:
        case quartixConstants.LIVE_POSITION_FETCH_FAILURE:
        case quartixConstants.TRIP_SUMMARY_FETCH_FAILURE:
        case quartixConstants.ROUTE_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case quartixConstants.ENGINE_TIMES_CLEAR_REQUEST:
            return {
                ...initialState
            };
        case authenticationConstants.LOGOUT:
            return {
                ...initialState
            };
        default:
            return state
    }
}
