import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import Constants from "../../utils/constants";
import {laveusesActions} from "../../modules/laveuses/laveuses.actions";
import {FormatListBulleted, LocalShipping, People, Replay, Tablet} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import Grid from "@mui/material/Grid2";
import LaveuseMap from "./laveuse/LaveuseMap.js";
import LaveuseListPanel from "./laveuse/LaveuseListPanel.js";
import {useParams} from "react-router-dom";
import {
    Box,
    ButtonGroup,
    CircularProgress,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import {parseISO} from "date-fns";
import DashboardLastUpdatedTimer from "./dashboard/DashboardLastUpdatedTimer.js";
import {dashboardActions} from "../../modules/dashboard/dashboard.actions.js";
import moment from "moment-timezone";
import {quartixActions} from "../../modules/quartix/quartix.actions.js";
import {MapContainer, Marker, Polygon, Polyline, Popup, useMap} from "react-leaflet";
import CustomTileLayer from "./layout/map/CustomTileLayer.js";
import {divIcon} from 'leaflet';
import L from 'leaflet';
import FullscreenControl from "./map/FullscreenControl.js";
import {LatLngBounds} from 'leaflet';
import * as turf from '@turf/turf';
import markerGris from '../../assets/images/marker-gris.png';
import markerBleu from '../../assets/images/marker-vert.png'
import {EngineOnIcon} from "../../utils/ui/Icons/EngineOnIcon.js";
import {EngineOffIcon} from "../../utils/ui/Icons/EngineOff.js";
import {helper} from "../../utils/helper.js";


const startIcon = new L.Icon({
    iconUrl: '/images/marker-icon-blue.png',
    shadowUrl: '/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

// Exemple : pin noir pour l’arrivée
const endIcon = new L.Icon({
    iconUrl: '/images/marker-icon-black.png',
    shadowUrl: '/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

function FitBounds({points}) {
    const map = useMap();

    useEffect(() => {
        if (points.length > 0) {
            const bounds = new LatLngBounds(points);
            map.fitBounds(bounds, {padding: [50, 50]}); // padding pour éviter d'être trop collé aux bords
        }
    }, [points, map]);

    return null;
}

const formatDate = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date.toISOString().split('T')[0]; // Convertit la date en format YYYY-MM-DD
};

const polygonColors = ['rgba(0, 123, 255, 0.2)', 'rgba(220, 53, 69, 0.2)', 'rgba(40, 167, 69, 0.2)'];
const borderColors = ['#007bff', '#dc3545', '#28a745'];


function LaveuseTripsPage({dispatch, ...props}) {
    const params = useParams();
    const [laveuseId, setLaveuseId] = useState(null);
    const [selectedDate, setSelectedDate] = React.useState(formatDate(0))
    const [showTourneeData, setShowTourneeData] = React.useState(false)

    const tripSummary = props.tripSummaries?.[props.laveuse?.quartixId]?.[selectedDate];
    const routeData = props.routeDataMap?.[props.laveuse?.quartixId]?.[selectedDate];
    const routePoints = routeData?.Trips?.flatMap(trip => trip.Route.map(p => [p.Latitude, p.Longitude])) ?? [];
    const stops = routeData?.Trips?.flatMap(trip =>
        trip.Route.filter(p => p.EventType === "Stop").map(p => [p.Latitude, p.Longitude])
    );

    /** COnstruction des data du contexte du jour (tournée + conteneurs + intervention) pour superposition **/
    const tournees = props.laveuseContext?.tournees ?? [];
    const tourneesLibelle = tournees.map((t) => t.libelle);
    const interventions = props.laveuseContext?.interventions ?? [];

    const interventionConteneurIds = new Set(interventions.map(i => i.conteneur_id));

    const conteneursWithStatus = tournees.flatMap(tournee =>
        tournee.conteneurs.map(c => ({
            ...c,
            interventionDone: interventionConteneurIds.has(c.id),
            tourneeId: tournee.id,
        }))
    );

    const noContextData = !props.laveuseContext || Object.keys(props.laveuseContext).length === 0;


    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setLaveuseId(params.id);
        }
    }, [props.laveuse, props.loading, props.error]);

    useEffect(() => {
        if (laveuseId && (props.laveuse?.id !== parseInt(laveuseId)) && !props.loading && !props.error) {
            dispatch(laveusesActions.fetchLaveuse(laveuseId));
        }
    }, [props, dispatch, laveuseId]);

    useEffect(() => {
        if (props.laveuse?.quartixId && selectedDate) {
            dispatch(quartixActions.fetchTripSummary(selectedDate, props.laveuse?.quartixId));
            dispatch(quartixActions.fetchRoute(selectedDate, props.laveuse?.quartixId));
        }
    }, [props.laveuse, selectedDate, dispatch]);

    useEffect(() => {
        if (props.laveuse?.id && selectedDate) {
            dispatch(laveusesActions.fetchDailyContext(selectedDate, props.laveuse.id));
        }

        if (props.laveuse?.quartixId && !props.quartixData?.[props.laveuse?.quartixId]?.[selectedDate]) {
            dispatch(quartixActions.fetchEngineTimes(selectedDate, props.laveuse?.quartixId));
        }
    }, [props.laveuse, selectedDate, dispatch]);


    const handleSelectDateByDays = (days) => {
        const formattedDate = formatDate(days);
        setSelectedDate(formattedDate);
    };

    const handleSelectedDate = (newDate) => {
        const date = moment(newDate).format("YYYY-MM-DD");
        setSelectedDate(date)
    }

    const getReadableDuration = (travelTime) => {
        if (!travelTime) return "---";
        const totalMinutes = Math.round(travelTime * 24 * 60);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return hours > 0 ? `${hours}h${minutes}min` : `${minutes}min`;
    };

    const getIcon = (c, count) => {
        let imgSrc = c.interventionDone
            ? markerBleu
            : markerGris;

        return divIcon({
            className: 'my-div-icon',
            html: `<img class="marker-image" src="${imgSrc}"/>` +
                `<span class="marker-number-div">${count}</span>`,
            iconSize: [(56 / 1.5), (76 / 1.5)],
        });
    }

    const getEngineTimeLabel = (laveuse, ignitionType) => {
        if (props.quartixData) {
            let qdata = props?.quartixData?.[props.laveuse?.quartixId];
            if (ignitionType === "on") {
                if (qdata) {
                    let data = qdata[selectedDate]?.ignition_on
                    if (data) {
                        return helper.getFormattedTime(data)
                    }
                }
            } else if (ignitionType === "off") {
                if (qdata) {
                    let data = qdata[selectedDate]?.ignition_off
                    if (data) {
                        return helper.getFormattedTime(data)
                    }
                }
            }
        }


        return null;
    }

    // const getKilometers = (laveuse) => {
    //     if (laveuse?.kilometrageDebut && laveuse.kilometrageFin) {
    //         return laveuse.kilometrageFin - laveuse.kilometrageDebut + " Km"
    //     }
    //     return "- Km"
    // }

    return (
        <>
            {props.loading &&
                <Loader/>
            }
            {!props.loading &&
                <>
                    {/* HEADER + DATE SELECTOR */}
                    <Grid container justifyContent="space-between" spacing={2} p={2} pb={0}>
                        <Grid item>
                            <Typography variant="h5">{props.laveuse?.description}</Typography>
                            <Typography variant="body2">{props.laveuse?.immatriculation}</Typography>
                        </Grid>
                        {/* Bloc droite : toggle + date picker */}
                        <Grid item>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    className="dashboard-button-pickers"
                                    size="large"
                                    name="bilanType"
                                    value={selectedDate}
                                    onChange={(event, newValue) => {
                                        handleSelectDateByDays(newValue);
                                    }}
                                    aria-label="Date"
                                >
                                    <ToggleButton
                                        value={-1}
                                        className={selectedDate === formatDate(-1) ? "toggle-button button-selected-green" : "toggle-button"}
                                    >
                                        Hier
                                    </ToggleButton>
                                    <ToggleButton
                                        value={0}
                                        className={selectedDate === formatDate(0) ? "toggle-button button-selected-green" : "toggle-button"}
                                    >
                                        Aujourd'hui
                                    </ToggleButton>
                                </ToggleButtonGroup>

                                <DatePicker
                                    value={parseISO(selectedDate)}
                                    displayWeekNumber={true}
                                    onChange={(value) => handleSelectedDate(value)}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    {/* RESUME TRAJET */}
                    <Box p={2}>
                        <Paper elevation={1} sx={{p: 2}}>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Stack direction={"column"} spacing={2}>
                                    {props.quartixLoading &&
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <CircularProgress/>
                                        </Stack>
                                    }
                                    {!props.quartixLoading && tripSummary && (
                                        <>
                                            <Typography variant="subtitle2">Résumé du trajet
                                                du {tripSummary.StartDate} </Typography>
                                            <Typography variant="body2">
                                                Distance : {tripSummary.Distance?.toFixed(2)} km
                                            </Typography>
                                            <Typography variant="body2">
                                                Durée (roulage) : {getReadableDuration(tripSummary.TravelTime)}
                                            </Typography>
                                            <Typography variant="body2">
                                                Vitesse moyenne
                                                : {(tripSummary.Distance / (tripSummary.TravelTime * 24)).toFixed(1)} km/h
                                            </Typography>
                                        </>

                                    )}
                                    <Stack direction={"row"}
                                           justifyContent="space-between"
                                           alignItems={"center"}>
                                        <Stack direction={"column"}>
                                            {/*<Stack direction={"row"}*/}
                                            {/*       alignItems={"center"}>*/}
                                            {/*    <FiberManualRecord/>*/}
                                            {/*    <Typography variant={"body2"}>*/}
                                            {/*        {laveuse.kilometrageDebut ? (laveuse.kilometrageDebut + " km") : "---"}*/}
                                            {/*    </Typography>*/}
                                            {/*</Stack>*/}

                                            <Stack direction={"row"}
                                                   alignItems={"center"} spacing={2}>
                                                <EngineOnIcon style={{fontSize: "24px"}}/>
                                                <Typography variant={"body2"}>
                                                    {getEngineTimeLabel(props.laveuse, "on") ??
                                                        <CircularProgress size={16}/>}
                                                </Typography>
                                            </Stack>
                                        </Stack>


                                        <Stack direction={"column"} sx={{flexGrow: 1}}>
                                            <div className="line"></div>
                                            {/*<Typography variant={"body2"} textAlign={"center"}>*/}
                                            {/*    {getKilometers(props.laveuse)}*/}
                                            {/*</Typography>*/}
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Stack direction={"row"}
                                                   alignItems={"center"} spacing={2}>
                                                <Typography variant={"body2"}>
                                                    {getEngineTimeLabel(props.laveuse, "off") ??
                                                        <CircularProgress size={16}/>}
                                                </Typography>
                                                <EngineOffIcon style={{fontSize: "24px"}}/>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack direction={"column"} spacing={2}>
                                    {props.contextLoading &&
                                        <CircularProgress/>
                                    }
                                    {!props.contextLoading && !props.contextError &&
                                        <>

                                            {noContextData &&
                                                <Typography variant="body2">Aucune donnée pour ce jour</Typography>
                                            }
                                            {!noContextData && props.laveuseContext &&
                                                <>
                                                    <Stack direction={"row"} spacing={1}>
                                                        <People sx={{color: Constants.COLOR_THEME_GREEN}}/>
                                                        <Typography
                                                            variant={"body2"}>{props.laveuseContext?.operateur?.libelle}</Typography>
                                                    </Stack>
                                                    <Typography variant="body2">
                                                        Tournéee : {tourneesLibelle.join(",")}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <ButtonGroup size="small">
                                                            <Button
                                                                variant={showTourneeData ? "contained" : "outlined"}
                                                                onClick={() => setShowTourneeData(true)}
                                                            >
                                                                Afficher la tournée
                                                            </Button>
                                                            <Button
                                                                variant={!showTourneeData ? "contained" : "outlined"}
                                                                onClick={() => setShowTourneeData(false)}
                                                            >
                                                                Masquer la tournée
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Typography>
                                                </>
                                            }

                                        </>
                                    }

                                </Stack>
                            </Stack>
                        </Paper>
                    </Box>

                    {/* MAP */}
                    {routePoints.length > 0 && (
                        <MapContainer
                            style={{height: "63vh", width: "100%"}}
                            center={routePoints[0]}
                            zoom={13}
                            scrollWheelZoom={true}
                        >
                            <CustomTileLayer maxZoom={19}/>

                            <FullscreenControl/>
                            <FitBounds points={routePoints}/>

                            {showTourneeData && tournees.map((tournee, index) => {
                                const rawPoints = tournee.conteneurs.map(c =>
                                    turf.point([parseFloat(c.longitude), parseFloat(c.latitude)])
                                );
                                if (rawPoints.length < 3) return null;

                                const featureCollection = turf.featureCollection(rawPoints);
                                const hull = turf.convex(featureCollection);

                                if (!hull) return null;

                                const polygonCoords = hull.geometry.coordinates[0].map(([lng, lat]) => [lat, lng]);
                                const color = polygonColors[index % polygonColors.length];
                                const borderColor = borderColors[index % borderColors.length];

                                return (
                                    <Polygon
                                        key={`polygon-${tournee.id}`}
                                        positions={polygonCoords}
                                        pathOptions={{
                                            color: borderColor,
                                            fillColor: color,
                                            fillOpacity: 1,
                                            weight: 1,
                                        }}
                                    />
                                );
                            })}


                            <Polyline positions={routePoints} pathOptions={{weight: 4, color: '#007bff'}}/>
                            <Marker position={routePoints[0]} icon={startIcon}>
                                <Popup>Départ</Popup>
                            </Marker>
                            <Marker position={routePoints[routePoints.length - 1]} icon={endIcon}>
                                <Popup>Arrivée</Popup>
                            </Marker>
                            {stops?.map((pos, i) => (
                                <Marker
                                    key={`stop-${i}`}
                                    position={pos}
                                    icon={L.divIcon({
                                        className: 'stop-icon',
                                        html: `<div style="
                                            width: 10px;
                                            height: 10px;
                                            background: red;
                                            border: 2px solid white;
                                            border-radius: 50%;
                                            box-shadow: 0 0 2px rgba(0,0,0,0.3);
                                          "></div>`,
                                        iconSize: [12, 12],
                                    })}
                                />
                            ))}
                            {showTourneeData && conteneursWithStatus.map((c, idx) => (
                                <Marker
                                    key={`conteneur-${c.id}`}
                                    position={[c.latitude, c.longitude]}
                                    icon={getIcon(c, idx + 1)}
                                >
                                    <Popup>
                                        {c.adresse}<br/>
                                        {c.interventionDone ? "✅ Lavé" : "⏳ Non lavé"}
                                    </Popup>
                                </Marker>
                            ))}

                        </MapContainer>
                    )}

                </>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        laveuse: state.laveuses.laveuse,
        laveuseContext: state.laveuses.context,
        loading: state.laveuses.loading,
        quartixData: state.quartix.data,
        quartixLoading: state.quartix.loading,
        contextLoading: state.laveuses.contextLoading,
        tripSummaries: state.quartix.tripSummary,
        routeDataMap: state.quartix.routeData,
        error: state.laveuses.error,
        Contexterror: state.laveuses.contextError
    };
}

export default connect(mapStateToProps)(LaveuseTripsPage);
