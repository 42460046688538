export const campaignsConstants = {
    CAMPAIGNS_FETCH_REQUEST: "CAMPAIGNS_FETCH_REQUEST",
    CAMPAIGNS_FETCH_SUCCESS: "CAMPAIGNS_FETCH_SUCCESS",
    CAMPAIGNS_FETCH_FAILURE: "CAMPAIGNS_FETCH_FAILURE",

    CAMPAIGNS_FETCH_ONE_REQUEST: "CAMPAIGNS_FETCH_ONE_REQUEST",
    CAMPAIGNS_FETCH_ONE_SUCCESS: "CAMPAIGNS_FETCH_ONE_SUCCESS",
    CAMPAIGNS_FETCH_ONE_FAILURE: "CAMPAIGNS_FETCH_ONE_FAILURE",

    CAMPAIGNS_CREATE_REQUEST: "CAMPAIGNS_CREATE_REQUEST",
    CAMPAIGNS_CREATE_SUCCESS: "CAMPAIGNS_CREATE_SUCCESS",
    CAMPAIGNS_CREATE_FAILURE: "CAMPAIGNS_CREATE_FAILURE",

    CAMPAIGNS_UPDATE_REQUEST: "CAMPAIGNS_UPDATE_REQUEST",
    CAMPAIGNS_UPDATE_SUCCESS: "CAMPAIGNS_UPDATE_SUCCESS",
    CAMPAIGNS_UPDATE_FAILURE: "CAMPAIGNS_UPDATE_FAILURE",

    CAMPAIGNS_DELETE_REQUEST: "CAMPAIGNS_DELETE_REQUEST",
    CAMPAIGNS_DELETE_SUCCESS: "CAMPAIGNS_DELETE_SUCCESS",
    CAMPAIGNS_DELETE_FAILURE: "CAMPAIGNS_DELETE_FAILURE",

    CAMPAIGNS_FETCH_DETAILS_REQUEST: 'CAMPAIGNS_FETCH_DETAILS_REQUEST',
    CAMPAIGNS_FETCH_DETAILS_SUCCESS: 'CAMPAIGNS_FETCH_DETAILS_SUCCESS',
    CAMPAIGNS_FETCH_DETAILS_FAILURE: 'CAMPAIGNS_FETCH_DETAILS_FAILURE',

    CAMPAIGNS_CLEAR_REQUEST: "CAMPAIGNS_CLEAR_REQUEST",

};
