import {dashboardConstants} from "./dashboard.constants";
import {dashboardService} from "./dashboard.service";


export const dashboardActions = {
    fetchDashboardData,
    fetchDashboardDataV2,
};

function fetchDashboardData(values) {
    return dispatch => {
        dispatch(request());

        dashboardService.fetchDashboardData(values)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: dashboardConstants.DASHBOARD_FETCH_REQUEST}
    }

    function success(data) {
        return {type: dashboardConstants.DASHBOARD_FETCH_SUCCESS, data}
    }

    function failure(error) {
        return {type: dashboardConstants.DASHBOARD_FETCH_FAILURE, error}
    }
}

function fetchDashboardDataV2(values) {
    return dispatch => {
        dispatch(request());

        dashboardService.fetchDashboardDataV2(values)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: dashboardConstants.DASHBOARD_FETCH_REQUEST}
    }

    function success(data) {
        return {type: dashboardConstants.DASHBOARD_FETCH_V2_SUCCESS, data}
    }

    function failure(error) {
        return {type: dashboardConstants.DASHBOARD_FETCH_FAILURE, error}
    }
}
