import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {format, parseISO} from "date-fns";
import {useEffect, useState} from "react";
import {Check, Close, Edit} from "@mui/icons-material";
import {DateTimePicker, TimePicker} from "@mui/x-date-pickers";
import {helper} from "../../../../utils/helper.js";
import moment from "moment-timezone";
import Button from "@mui/material/Button";
import Constants from "../../../../utils/constants.js";

function DetailHeureModal({data, quartixData, open, handleClose, loading, onFdtUpdate, onFdtValidate, ...props}) {
    if (!data) return null;

    const [isEditingStart, setIsEditingStart] = useState(false);
    const [isEditingEnd, setIsEditingEnd] = useState(false);
    const [isEditingPause, setIsEditingPause] = useState(false);
    const [heureDebutValue, setHeureDebutValue] = useState(null);
    const [heureFinValue, setHeureFinValue] = useState(null);
    const [pauseValue, setPauseValue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const pauseData = Constants.TEMPS_PAUSE_MINUTES_DATA;

    useEffect(() => {
        setHeureDebutValue(data?.details?.debut ? moment.parseZone(data.details.debut) : null);
        setHeureFinValue(data?.details?.fin ? moment.parseZone(data.details.fin) : null);
        setPauseValue(data?.details?.tempsPauseMinutes || 0);
    }, [data]);


    const handleEditClick = (type) => {
        if (type === "start") {
            setIsEditingStart(true);
        } else if (type === "end") {
            setIsEditingEnd(true);
        } else if (type === "pause") {
            setIsEditingPause(true);
        }
    };

    const handleCancel = (type) => {
        if (type === "start") {
            setIsEditingStart(false);
            setHeureDebutValue(data?.details?.debut ? moment.parseZone(data.details.debut) : null);
        } else {
            setIsEditingEnd(false);
            setHeureFinValue(data?.details?.fin ? moment.parseZone(data.details.fin) : null);
        }
    };

    const handleSave = (type) => {
        let values = {
            id: data?.details?.idFdt,
        };

        if (type === "start") {
            // const combinedStart = combineDateAndTime(parseISO(data.details.debut), heureDebutValue);
            values.heureDebut = heureDebutValue.clone().utcOffset(0, true).toISOString();
            setIsEditingStart(false);
        } else if (type === "end") {
            // const combinedEnd = combineDateAndTime(parseISO(data.details.fin), heureFinValue);
            values.heureFin = heureFinValue.clone().utcOffset(0, true).toISOString();
            setIsEditingEnd(false);
        } else if (type === "pause") {
            values.tempsPauseMinutes = pauseValue
            setIsEditingPause(false)
        }

        if (onFdtUpdate) {
            onFdtUpdate(values);
        }
    };

    const getTempsPauseLabel = (tempsPause) => {
        const option = Constants.TEMPS_PAUSE_MINUTES_DATA.find(item => item.value === tempsPause);
        return option ? option.label : "N/A";
    };

    const getTempsTravail = (workMinutes) => {
        const totalMinutes = workMinutes || 0; // Default to 0 if not present
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        return `${hours}h${minutes > 0 ? minutes + "m" : ""}`
    }

    const validateFdt = (fdtId) => {
        setIsLoading(true)
        onFdtValidate(fdtId)
        setTimeout(() => {
            setIsLoading(false)

        }, 2000)
    }

    // Quartix data
    const {date, details} = data;

    const quartixIdDebut = details?.quartixIdDebut;
    const quartixIdFin = details?.quartixIdFin;

    const engineTimesDebut = quartixData[quartixIdDebut]?.[date];
    const engineTimesFin = quartixIdFin ? quartixData[quartixIdFin]?.[date] : null;

    return (<>
        {data && <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={8}>
                        <span>Feuille de temps du {format(new Date(data.date), "d/M/y")} - {data.operateur}</span>
                    </Grid>
                    <Grid item>
                        {data?.details?.validatedAt ? "Fiche validée le " + helper.getFormattedDatetime(data?.details?.validatedAt) : "Fiche non validée"}
                    </Grid>
                </Grid>

            </DialogTitle>
            <DialogContent>
                <Typography variant={"caption"}>Feuille de temps n° {data?.details?.idFdt}</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Démarrage camion</TableCell>
                            <TableCell align="center" sx={{
                                width: "300px",
                                minHeight: "60px",
                                padding: "8px",
                                fontSize: "14px",
                                textAlign: "center",
                            }}>Prise de poste</TableCell>
                            <TableCell align="center">Premier lavage</TableCell>
                            <TableCell align="center">Temps de pause</TableCell>
                            <TableCell align="center">Dernier lavage</TableCell>
                            <TableCell align="center">Fin de poste</TableCell>
                            <TableCell align="center">Arrêt camion</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">
                                {loading ? (<CircularProgress size={16}/>) : (<>
                                    {engineTimesDebut?.ignition_on && (<Typography variant={"body2"}>
                                        {details.laveuseDebut} : {format(new Date(engineTimesDebut.ignition_on), "HH:mm")}
                                    </Typography>)}
                                    {engineTimesFin?.ignition_on && quartixIdFin && (<Typography variant={"body2"}>
                                        {details.laveuseFin} : {format(new Date(engineTimesFin.ignition_on), "HH:mm")}
                                    </Typography>)}
                                    {!engineTimesDebut?.ignition_on && !engineTimesFin?.ignition_on && "N/A"}
                                </>)}
                            </TableCell>
                            <TableCell align="center">
                                <Box display="flex" alignItems="center" justifyContent="center">

                                    {isEditingStart ? (<>
                                        <>
                                            <DateTimePicker
                                                label="Heure de début"
                                                value={heureDebutValue ? new Date(heureDebutValue.format("YYYY-MM-DDTHH:mm:ss")) : null}
                                                onChange={(newValue) => setHeureDebutValue(moment(newValue))}
                                                renderInput={(params) => (
                                                    <TextField {...params} size="small" style={{width: "130px"}}/>)}
                                            />
                                            <IconButton onClick={() => handleSave("start")} color="primary"
                                                        size="small">
                                                <Check/>
                                            </IconButton>
                                            <IconButton onClick={() => handleCancel("start")} color="secondary"
                                                        size="small">
                                                <Close/>
                                            </IconButton>
                                        </>
                                    </>) : (<>
                                        <span style={{marginRight: '.25rem'}}>
                                            {heureDebutValue ? helper.formatDate(heureDebutValue) : ""}
                                        </span>
                                        {heureDebutValue ? helper.getFormattedTime(heureDebutValue) : "N/A"}
                                        {heureDebutValue && <IconButton
                                            onClick={() => handleEditClick("start")}
                                            size="small" color="primary">
                                            <Edit/>
                                        </IconButton>}
                                    </>)}
                                </Box>
                            </TableCell>
                            <TableCell align="center">
                                {data.details?.premiereIntervention ? helper.getFormattedTime(data.details.premiereIntervention) : "N/A"}
                            </TableCell>
                            <TableCell align="center">
                                {isEditingPause ? (<Box display="flex" alignItems="center" justifyContent="center">
                                    <Select
                                        size="small"
                                        value={pauseValue}
                                        onChange={(e) => setPauseValue(e.target.value)}
                                        style={{width: "130px"}}
                                    >
                                        {pauseData.map((p) => (<MenuItem key={p.value} value={p.value}>
                                            {p.label}
                                        </MenuItem>))}
                                    </Select>

                                    <IconButton onClick={() => handleSave("pause")} color="primary" size="small">
                                        <Check/>
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            setIsEditingPause(false);
                                            setPauseValue(data.details.tempsPauseMinutes || 0);
                                        }}
                                        color="secondary"
                                        size="small"
                                    >
                                        <Close/>
                                    </IconButton>
                                </Box>) : (<Box display="flex" alignItems="center" justifyContent="center">
                                    {getTempsPauseLabel(data?.details?.tempsPauseMinutes)}

                                    <IconButton
                                        onClick={() => handleEditClick("pause")}
                                        size="small"
                                        color="primary"
                                    >
                                        <Edit/>
                                    </IconButton>
                                </Box>)}
                            </TableCell>

                            <TableCell align="center">
                                {data.details?.derniereIntervention ? helper.getFormattedTime(data.details.derniereIntervention) : "N/A"}
                            </TableCell>
                            <TableCell align="center">
                                <Box display="flex" alignItems="center" justifyContent="center">


                                    {isEditingEnd ? (<>
                                        <DateTimePicker
                                            label="Heure de début"
                                            value={heureFinValue.toDate()}
                                            onChange={(newValue) => setHeureFinValue(moment(newValue))}
                                            renderInput={(params) => (
                                                <TextField {...params} size="small" style={{width: "130px"}}/>)}
                                        />
                                        <IconButton onClick={() => handleSave("end")} color="primary"
                                                    size="small">
                                            <Check/>
                                        </IconButton>
                                        <IconButton onClick={() => handleCancel("end")} color="secondary"
                                                    size="small">
                                            <Close/>
                                        </IconButton>

                                    </>) : (<>
                                        <span style={{marginRight: '.25rem'}}>
                                            {heureFinValue ? helper.formatDate(heureFinValue) : ""}
                                        </span>
                                        {heureFinValue ? helper.getFormattedTime(heureFinValue) : "N/A"}
                                        {heureFinValue && <IconButton
                                            onClick={() => handleEditClick("end")}
                                            size="small" color="primary">
                                            <Edit/>
                                        </IconButton>}
                                    </>)}
                                </Box>
                            </TableCell>
                            <TableCell align="center">
                                {loading ? (<CircularProgress size={16}/>) : (<>
                                    {engineTimesDebut?.ignition_off && (<Typography variant={"body2"}>
                                        {details.laveuseDebut} : {format(new Date(engineTimesDebut.ignition_off), "HH'h'mm")}
                                    </Typography>)}
                                    {engineTimesFin?.ignition_off && quartixIdFin && (<Typography variant={"body2"}>
                                        {details.laveuseFin} : {format(new Date(engineTimesFin.ignition_off), "HH'h'mm")}
                                    </Typography>)}
                                    {!engineTimesDebut?.ignition_off && !engineTimesFin?.ignition_off && "N/A"}
                                </>)}
                            < /TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box className={"mt-2"}>
                    {data?.details?.client && <Typography>Client : {data.details.client}</Typography>}

                    {data?.details?.tourneeLibelle && <Typography>
                        Tournée : {data.details.tourneeLibelle}
                    </Typography>}

                    {data?.details?.nbBacs && <Typography>
                        Nombre de bacs : {data.details.nbBacs + ' bacs'}
                    </Typography>}

                    {data?.details?.tempsPauseMinutes != null && data?.details?.debut && data?.details?.fin &&
                        <Typography>
                            Temps de travail : {getTempsTravail(data?.details?.workMinutes)}
                        </Typography>}
                    {data?.details?.laveuseDebut && (<Typography>
                        Laveuse en début de service
                        : {data.details.laveuseDebut} {data.details?.kmLaveuseDebut && "(" + data.details?.kmLaveuseDebut + " km)"}
                    </Typography>)}
                    {data?.details?.laveuseFin && (<Typography>
                        Laveuse en fin de service
                        : {data.details.laveuseFin} {data.details?.kmLaveuseFin && "(" + data.details?.kmLaveuseFin + " km)"}
                    </Typography>)}
                    {data?.details?.equipier && <Typography>Equipier : {data.details.equipier}</Typography>}
                    {data?.details?.commentaireRH && <Typography>
                        Commentaire RH : {data.details.commentaireRH}
                    </Typography>}
                </Box>
                <Box justifyContent="end" display="flex">
                    <Button variant={"contained"} color="secondary" onClick={() => validateFdt(data?.details?.idFdt)}>
                        {isLoading ? <CircularProgress size={24}/> : "Valider la feuille de temps"}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>}
    </>)
}

export default DetailHeureModal;
