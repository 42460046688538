import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import Constants from "../../utils/constants";
import {laveusesActions} from "../../modules/laveuses/laveuses.actions";
import {LocalShipping} from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PageTitle from "./layout/PageTitle";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CardActions,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {useParams} from "react-router-dom";
import {helper} from "../../utils/helper.js";
import ValueLabelRow from "./layout/ValueLabelRow.js";
import PhotoCarousel from "./layout/PhotoCarousel.js";


function LaveuseJournalPage({dispatch, ...props}) {
    const params = useParams();
    const [laveuseId, setLaveuseId] = useState(null)
    const [openCorrectionModal, setOpenCorrectionModal] = useState(false)

    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setLaveuseId(params.id);
        } else {
            setLaveuseId(null)
        }
    }, [params]);

    useEffect(() => {
        if (laveuseId && (props.laveuse?.id !== parseInt(laveuseId)) && !props.loading && !props.error) {
            dispatch(laveusesActions.fetchLaveuse(laveuseId, "laveusewithjournal"));
        }
    }, [props, dispatch, laveuseId]);

    const getPhotoArray = (photoObjectsArray) => {
        return photoObjectsArray.map(p => p.filepath);
    }

    const handleOpenCorrectionModal = () => setOpenCorrectionModal(true);
    const handleCloseCorrectionModal = () => setOpenCorrectionModal(false);

    const handleCorrectLaveuseStatus = () => {
        let values = {id: props.laveuse?.id, statut: 1}
        dispatch(laveusesActions.updateLaveuse(values, true))
    };

    return (
        <>
            <PageTitle title={`Journal de la laveuse ${props.laveuse?.description}`} icon={<LocalShipping/>}/>
            {props.loading &&
                <Loader/>
            }
            {!props.loading &&
                <>
                    <Grid container justifyContent={"center"} spacing={2} className={"mb-2"}>
                        <Grid item xs={6}>
                            <Card variant={"outlined"}>
                                <CardContent>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant="h5" component="div">
                                            Fiche de la laveuse {props.laveuse?.description}
                                        </Typography>
                                        <Chip
                                            label={helper.getLaveuseImportanceLibelle(props.laveuse?.statut)}
                                            style={{
                                                backgroundColor: helper.getLaveuseImportanceColor(props.laveuse?.statut),
                                                color: 'white'
                                            }}
                                        />
                                    </Box>

                                    <Typography sx={{
                                        color: 'text.secondary',
                                        mb: 1.5
                                    }}>
                                        Immatriculation {props.laveuse?.immatriculation}
                                    </Typography>
                                    <Typography variant="body2">
                                        Dernière utilisation
                                        le {helper.formatDate(props.laveuse?.lastTenJournalLaveuse[0]?.date)}
                                        &nbsp; par {props.laveuse?.lastTenJournalLaveuse[0]?.operateur?.libelle}
                                    </Typography>
                                    <Typography variant={"body2"}>
                                        Actuellement : {props.laveuse?.live?.LocationText}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{"justifyContent": "end"}}>
                                    {props.laveuse?.statut > 1 &&
                                        <Button variant={"contained"}
                                                color={"secondary"}
                                                sx={{'color': 'white'}}
                                                onClick={() => setOpenCorrectionModal(true)}>
                                            CORRIGER
                                        </Button>
                                    }
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" component="div" className={"mb-1"}>
                                Historique de la laveuse
                            </Typography>

                            {props.laveuse?.lastTenJournalLaveuse.map((jl, index) => {
                                return (
                                    <Accordion key={index}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                            <Typography className={"mr-2"}>
                                                {helper.formatDate(jl.date)}
                                            </Typography>
                                            {jl.problems.map((pb, i) => {
                                                return (
                                                    <Chip
                                                        key={`${index}-${i}`}
                                                        label={pb.libelle}
                                                        style={{
                                                            backgroundColor: helper.getProblemeImportanceColor(pb.importance),
                                                            color: 'white',
                                                            marginLeft: '.5rem',
                                                            marginRight: '.5rem'
                                                        }}
                                                    />
                                                )
                                            })}
                                            {jl.problems.length === 0 && (
                                                <Chip
                                                    label={"RAS"}
                                                    style={{
                                                        backgroundColor: helper.getProblemeImportanceColor(1),
                                                        color: 'white',
                                                        marginLeft: '.5rem',
                                                        marginRight: '.5rem'
                                                    }}
                                                />
                                            )}
                                            {jl.remarque &&
                                                <Typography sx={{marginRight: '0.25rem'}}>({jl.remarque})</Typography>
                                            }
                                            {jl.kilometrageDebut != null && jl.kilometrageFin != null && (
                                                <span>{jl.kilometrageFin - jl.kilometrageDebut} km</span>
                                            )}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Typography className={"carousel-label carousel-blue"}
                                                                variant={"h6"}>Données</Typography>
                                                    <ValueLabelRow label={"Chauffeur"}
                                                                   value={jl.operateur?.libelle}/>
                                                    <ValueLabelRow label={"Kilométrage de début"}
                                                                   value={jl.kilometrageDebut ? jl.kilometrageDebut + "km" : "Non renseigné"}/>
                                                    <ValueLabelRow label={"Kilométrage de fin"}
                                                                   value={jl.kilometrageFin ? jl.kilometrageFin + "km" : "Non renseigné"}/>
                                                    <Typography className={"carousel-label carousel-green mt-2"}
                                                                variant={"h6"}>Problèmes</Typography>
                                                    {jl.problems && jl.problems.map(pb => {
                                                        let label = pb.libelle
                                                        if (label === "Autre" && jl.remarque) {
                                                            label += " : " + jl.remarque
                                                        }
                                                        return (
                                                            <Typography>{label}</Typography>
                                                        )

                                                    })}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {jl.problems.length > 0 && (
                                                        <PhotoCarousel
                                                            photoArray={getPhotoArray(jl.photos)}
                                                            label="Photo du signalement"
                                                            headerStyle="carousel-orange"
                                                            assetsUrl={`${Constants.API_ASSETS_PHOTO_INTERVENTION}/`}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>

                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                            }

                        </Grid>
                    </Grid>

                    {/* Modal de correction du statut de la laveuse */}
                    <Dialog open={openCorrectionModal} onClose={handleCloseCorrectionModal}>
                        <DialogTitle>Corriger le statut</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Indiquer la résolution des problèmes ? La laveuse sera de nouveau disponible.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCorrectionModal} color="primary">
                                Annuler
                            </Button>
                            <Button onClick={handleCorrectLaveuseStatus} color="secondary" variant="contained"
                                    sx={{color: 'white'}}>
                                Corriger
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        laveuse: state.laveuses.laveuse,
        loading: state.laveuses.loading,
        error: state.laveuses.error
    }
}

export default connect(mapStateToProps)(LaveuseJournalPage);
