import {
    deleteHeaders,
    fetchHeaders,
    patchHeaders,
    postHeaders,
} from "../../utils/headers";
import Constants from "../../utils/constants";

export const laveusesService = {
    fetchLaveuses,
    fetchLaveuse,
    deleteLaveuse,
    createLaveuse,
    updateLaveuse,
    fetchDailyContext
};

function fetchLaveuses() {
    const requestOptions = fetchHeaders()

    return fetch(Constants.API_ROUTE_LAVEUSES, requestOptions)
        .then(handleResponse)
        .then(laveuses => {
            return laveuses["hydra:member"]
        });
}

function fetchLaveuse(id, serializationgroup = null) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_LAVEUSE.replace(':id', id);
    if (serializationgroup) {
        url += "?groups="+serializationgroup
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(laveuse => {
            return laveuse
        });
}

function createLaveuse(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_LAVEUSES;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function fetchDailyContext(date, vehicleId) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_LAVEUSE_DAILY_CONTEXT.replace(":date", date).replace(":id", vehicleId)

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then((data) => {
            return data
        });
}

function updateLaveuse(values) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_LAVEUSE.replace(':id', values.id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function deleteLaveuse(id) {
    const requestOptions = deleteHeaders()

    let url = Constants.API_ROUTE_LAVEUSE.replace(':id', id);
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return id
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
