import React, {useEffect} from 'react';
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import PageTitle from "./layout/PageTitle";
import {Grid} from "@mui/material";
import SchemaIcon from "@mui/icons-material/Schema";
import {rhdataActions} from "../../modules/rhdata/rhdata.actions.js";
import RHDataTable from "./rh/RHDataTable.js";


function RHDataPage({dispatch, ...props}) {

    useEffect(() => {
        if (!props.rhdatas && !props.loading && !props.error) {
            dispatch(rhdataActions.fetchRhdatas())
        }
    }, [props.rhdatas, props.loading, props.error]);


    const handleDelete = (laveuse) => {
        dispatch(rhdataActions.deleteRhdata(laveuse.id));
    }

    return (
        <>
            <PageTitle title={"Données RH"} icon={<SchemaIcon/>}/>
            {props.loading &&
                <Loader/>
            }
            {!props.loading &&
                <>
                    <Grid container>
                        <RHDataTable rhdatas={props.rhdatas}/>
                    </Grid>
                </>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        rhdatas: state.rhdata.rhdatas,
        loading: state.rhdata.loading,
        error: state.rhdata.error
    }
}

export default connect(mapStateToProps)(RHDataPage);
