import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {ClockIcon, DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {heuresActions} from "../../modules/heures/heures.actions.js";
import RHSuiviHeuresTable from "./rh/RHSuiviHeuresTable.js";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {frFR} from '@mui/x-date-pickers/locales';
import {fr} from 'date-fns/locale';
import {FileExcel2} from '@styled-icons/remix-fill/FileExcel2';
import styled from "styled-components";
import {helper} from "../../utils/helper.js";
import moment from "moment-timezone";
import {useNavigate} from "react-router-dom";
import Constants from "../../utils/constants.js";

const ExcelIcon = styled(FileExcel2)`color: white;
    width: 24px;`;

function RHSuiviHeures({dispatch, ...props}) {
    const navigate = useNavigate();
    const [selectedPeriodeId, setSelectedPeriodeId] = useState(() => {
        const stored = localStorage.getItem("selectedPeriodeId");
        return stored ? Number(stored) : null;
    });

    const selectedPeriode = props.periodePaies?.length
        ? props.periodePaies.find((p) => p.id === selectedPeriodeId) || null
        : null;

    const [dateRange, setDateRange] = useState({startDate: null, endDate: null});

    useEffect(() => {
        // Récupère les périodes
        dispatch(heuresActions.fetchPeriodePaies());
    }, [dispatch]);

    useEffect(() => {
        if (selectedPeriode) {
            const startDate = new Date(selectedPeriode.startDate);
            const endDate = new Date(selectedPeriode.endDate);

            setDateRange({startDate, endDate});

            // Fetch heures pour la plage de dates
            dispatch(
                heuresActions.fetchHeures(
                    moment(startDate).format("YYYY-MM-DD"), // Strictement au format local
                    moment(endDate).format("YYYY-MM-DD")
                )
            );
        }
    }, [selectedPeriode, dispatch, props.periodePaies]);

    useEffect(() => {
        if (!selectedPeriodeId && props.periodePaies?.length > 0) {
            const lastSavedPeriode = localStorage.getItem("selectedPeriodeId");
            const defaultPeriode = props.periodePaies[0].id; // Sélection par défaut si rien en mémoire

            setSelectedPeriodeId(lastSavedPeriode || defaultPeriode);
        }
    }, [props.periodePaies]);


    const onSelectedPeriodeId = (id) => {
        if (id) {
            localStorage.setItem("selectedPeriodeId", id);
            setSelectedPeriodeId(id)
        }
    }

    const sortedPeriodes = () => {
        return Array.isArray(props.periodePaies)
            ? props.periodePaies
                .filter((periode) => periode.startDate && !isNaN(new Date(periode.startDate).getTime()))
                .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
            : [];
    };

    const handleExport = () => {
        if (selectedPeriode) {
            let startDate = moment(selectedPeriode.startDate).format("YYYY-MM-DD")
            let endDate = moment(selectedPeriode.endDate).format("YYYY-MM-DD")
            dispatch(heuresActions.exportSuiviHeures(startDate, endDate));
        } else {
            console.warn("Aucune période sélectionnée pour l'export");
        }
    };



    const handleSaisieHeureClick = () => {
        navigate(Constants.APP_ROUTE_RH_FDT_NEW)
    }

    return (
        <>
            <Typography variant="h4" gutterBottom>
                Suivi des heures
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDateFns}
                                  adapterLocale={fr}
                                  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <Grid container alignItems="center" spacing={2} style={{marginBottom: "20px"}}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="periode-select-label">Sélectionnez une période</InputLabel>
                            <Select
                                labelId="periode-select-label"
                                value={selectedPeriodeId || ""}
                                onChange={(event) =>
                                    onSelectedPeriodeId(event.target.value)
                                }
                                renderValue={() =>
                                    selectedPeriode
                                        ? `${selectedPeriode.libelle} (${helper.formatDate(selectedPeriode.startDate)} - ${helper.formatDate(selectedPeriode.endDate)})`
                                        : "Sélectionnez une période"
                                }>
                                {sortedPeriodes().map((periode) => (
                                    <MenuItem key={periode.id} value={periode.id}>
                                        {periode.libelle} ({helper.formatDate(periode.startDate)} - {helper.formatDate(periode.endDate)})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button variant={"contained"}
                                color={"secondary"}
                                startIcon={<ExcelIcon/>}
                                onClick={handleExport}
                                sx={{color: 'white'}}>
                            EXPORT
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant={"contained"}
                                color={"primary"}
                                startIcon={<ClockIcon/>}
                                onClick={handleSaisieHeureClick}
                                sx={{color: 'white'}}>
                            Saisie des heures
                        </Button>
                    </Grid>
                </Grid>
            </LocalizationProvider>

            {props.loading && <Loader/>}

            {props.heures && <RHSuiviHeuresTable heures={props.heures} dateRange={dateRange}/>}
        </>
    );
}

const mapStateToProps = function (state) {
    return {
        heures: state.heures.heures,
        periodePaies: state.heures.periodePaies,
        loading: state.heures.loading,
        exportLoading: state.heures.exportLoading,
        error: state.heures.error
    }
}

export default connect(mapStateToProps)(RHSuiviHeures);
