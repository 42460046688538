import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Dialog, DialogTitle, DialogContent, CircularProgress, Typography, Box, Stack, Paper} from "@mui/material";
import {campaignsActions} from "../../../modules/campaigns/campaigns.actions.js";
import {ArcherContainer, ArcherElement} from "react-archer";
import {helper} from "../../../utils/helper.js";

function DashboardModalDetailsWorkDone({open, onClose, date, tourneeId, dispatch, details, loading, error}) {

    useEffect(() => {
        if (open && date && tourneeId) {
            dispatch(campaignsActions.fetchDetails(date, tourneeId));
        }
    }, [open, date, tourneeId, dispatch]);

    const getStatutBgColor = (data) => {
        if (data) {
            if (!data.isAccessible) {
                return {backgroundColor: "#B22222", color: "white"}
            } else if (data.isAccessible && data.problems?.length > 0) {
                return {backgroundColor: "#e67e22", color: "white"}
            } else {
                return {backgroundColor: "#7bb421"}

            }
        }
    }


    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            {/*<DialogTitle>Détail du travail réalisé</DialogTitle>*/}
            <DialogContent>
                {loading && <CircularProgress/>}
                {error && <Typography color="error">Erreur : {JSON.stringify(error)}</Typography>}
                {!loading && !error && details && (
                    <>
                        <Paper sx={{padding: 2, maxHeight: '85vh', overflow: 'auto'}}>
                            <Typography variant="h6" gutterBottom textAlign={"center"}>
                                Comparaison de la tournée
                            </Typography>
                            <ArcherContainer strokeColor="#1976d2">
                                <Stack direction="row" spacing={6} sx={{width: "100%"}}>
                                    {/* Ordre prévu */}
                                    <Stack spacing={2} sx={{width: "50%"}}>
                                        <Typography variant="subtitle1" textAlign={"center"}>Ordre prévu</Typography>
                                        {details?.ordre_prevu.map((c, i) => (
                                            <ArcherElement
                                                key={`planned-${c.id}`}
                                                id={`planned-${c.id}`}
                                                relations={(() => {
                                                    const realIndex = details?.ordre_reel.findIndex(a => a.id === c.id);
                                                    if (realIndex === -1) return [];

                                                    const isSamePosition = realIndex === i;

                                                    return [{
                                                        targetId: `actual-${c.id}`,
                                                        targetAnchor: 'left',
                                                        sourceAnchor: 'right',
                                                        style: {
                                                            strokeColor: isSamePosition ? '#1976d2' : '#FF0000', // bleu si ok, rouge si ordre changé
                                                            strokeWidth: 2
                                                        }
                                                    }];
                                                })()}
                                            >
                                                <Box sx={{
                                                    border: '1px solid #ccc',
                                                    padding: 1,
                                                    borderRadius: 1,
                                                    width: '65%',
                                                    alignSelf: "start",
                                                    backgroundColor: '#f9f9f9'
                                                }}>
                                                    <Typography variant="body2">{i + 1}. {c.nomenclature}</Typography>
                                                    <Typography variant="caption">{c.adresse}</Typography>
                                                </Box>
                                            </ArcherElement>
                                        ))}
                                    </Stack>

                                    {/* Ordre réalisé */}
                                    <Stack spacing={2} sx={{width: "50%"}}>
                                        <Typography variant="subtitle1" textAlign={"center"}>Ordre réalisé</Typography>
                                        {details?.ordre_reel.map((c, i) => (
                                            <ArcherElement key={`actual-${c.id}`} id={`actual-${c.id}`}>
                                                <Box sx={{
                                                    border: '1px solid #ccc',
                                                    width: '65%',
                                                    alignSelf: "end",
                                                    padding: 1,
                                                    borderRadius: 1, ...getStatutBgColor(c)
                                                }}>
                                                    <Typography
                                                        variant="body2">{i + 1}. {c.nomenclature} - {helper.getFormattedTime(c.date_evenement)}</Typography>
                                                    <Typography variant="caption" sx={{display:"block"}}>{c.adresse}</Typography>
                                                    {c.problemes && c.problemes.length > 0 &&
                                                        <Typography variant="overline">
                                                            {c.problemes.join(",")}
                                                        </Typography>
                                                    }
                                                </Box>
                                            </ArcherElement>
                                        ))}
                                    </Stack>
                                </Stack>
                            </ArcherContainer>
                        </Paper>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = state => ({
    details: state.campaigns.details,
    loading: state.campaigns.detailsLoading,
    error: state.campaigns.detailsError,
});

export default connect(mapStateToProps)(DashboardModalDetailsWorkDone);
