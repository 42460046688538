import {
    deleteHeaders,
    fetchHeaders,
    patchHeaders,
    postHeaders,
} from "../../utils/headers";
import Constants from "../../utils/constants";

export const quartixService = {
    fetchEngineTimes,
    fetchLivePosition,
    fetchTripSummary,
    fetchRoute
};

function fetchEngineTimes(date, vehicleId) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_QUARTIX_ENGINE_TIMES;
    url = url.replace(":date", date)
    url = url.replace(":vehicleId", vehicleId)

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data
        });
}

function fetchLivePosition(vehicleIds) {
    const requestOptions = fetchHeaders();
    const query = vehicleIds.map(id => `vehicleIds[]=${id}`).join("&");
    const url = `${Constants.API_ROUTE_QUARTIX_LIVE}?${query}`;
    return fetch(url, requestOptions).then(handleResponse);
}

function fetchTripSummary(date, vehicleId) {
    const requestOptions = fetchHeaders();
    const url = Constants.API_ROUTE_QUARTIX_TRIP_SUMMARY
        .replace(":date", date)
        .replace(":vehicleId", vehicleId);
    return fetch(url, requestOptions).then(handleResponse);
}

function fetchRoute(date, vehicleId) {
    const requestOptions = fetchHeaders();
    const url = Constants.API_ROUTE_QUARTIX_ROUTE
        .replace(":date", date)
        .replace(":vehicleId", vehicleId);
    return fetch(url, requestOptions).then(handleResponse);
}



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
