import {useEffect} from "react";
import {useMap} from "react-leaflet";

export default function FlyToLaveuse({selected}) {
    const map = useMap();

    useEffect(() => {
        if (selected?.live?.Latitude && selected?.live?.Longitude) {
            map.flyTo([selected.live.Latitude, selected.live.Longitude], 18, {
                duration: 3
            });
        }
    }, [selected, map]);

    return null;
}
