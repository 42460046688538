import React, {useEffect} from 'react';

function PlanningPage({dispatch, ...props}) {

    const gsheetUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQax-k19ZRYjoWwxh1_4rPjYRhLHdjfBFhDytaogLIR8WKYny99FO2vCRjjy9EI20yfpn85f1A1XqbH/pubhtml?gid=940074906&amp;single=true&amp;widget=true&amp;headers=false";

    return (
        <div style={{width: '100%', height: '85vh'}}>
            <iframe
                title="Planning"
                src={gsheetUrl}
                style={{width: '100%', height: '100%', border: 'none'}}
            />
        </div>
    )
}

export default PlanningPage;
