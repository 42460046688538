import React from 'react';
import theme from "../../utils/ui/CustomTheme";
import {connect} from 'react-redux';
import Footer from "./layout/Footer";
import routes from "../routes";
import RequireAuth from "./route/RequireAuth"
import {Route, Routes, unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Layout.css';
import history from '../../utils/history';
import {Box, Container, ThemeProvider} from "@mui/material";
import AppDrawer from "./layout/AppDrawer";
import ContentHeader from "./layout/ContentHeader";

const initialDrawerState = () => {
    let state = window.localStorage.getItem("drawerOpen");
    return state === "true";
}

function Layout({dispatch, ...props}) {

    const [drawerOpen, setDrawerOpen] = React.useState(initialDrawerState());

    const onDrawerOpen = (value) => {
        setDrawerOpen(value);
        window.localStorage.setItem("drawerOpen", value);
    }

    return (
        <ThemeProvider theme={theme}>
            <HistoryRouter history={history}>
                <Box className={props.loggedIn ? "app" : "appLogin"}>
                    <ToastContainer position={'top-center'} closeOnClick/>
                    <Routes>
                        {routes.map((route) =>
                            !route.protected ? (
                                <Route key={route.path} {...route} />
                            ) : (

                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={
                                        <>
                                            <ContentHeader drawerOpen={drawerOpen} setDrawerOpen={onDrawerOpen}/>
                                            <div className="app-layout">
                                                <AppDrawer open={drawerOpen}/>
                                                <Container className="pageContainer" maxWidth={'xl'}>
                                                    <RequireAuth
                                                        loggedIn={props.loggedIn}
                                                        role={props.currentUser ? props.currentUser.roles : ''}
                                                        restrictedRoles={route.roles}
                                                        redirectTo="/login"
                                                    >
                                                        {route.element}
                                                    </RequireAuth>
                                                </Container>
                                            </div>

                                        </>

                                    }
                                />
                            )
                        )}
                    </Routes>
                    {/*</div>*/}
                    <Footer/>
                </Box>
            </HistoryRouter>
        </ThemeProvider>
    );
}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.authentication.loggedIn,
        currentUser: state.authentication.user
    }
}

export default connect(mapStateToProps)(Layout);
