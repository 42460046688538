import React from 'react';
import {Field} from 'formik';
import {
    FormControl, FormHelperText,
    Grid, InputLabel, MenuItem, Select,
    Typography
} from "@mui/material";


function CampaignOperateursField({...props}) {
    const {values, errors, operateurs} = props;


    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>Sélection des opérateurs</Typography>
            </Grid>

            {/* CHAUFFEUR FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    <Typography>Sélectionner le chauffeur *</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"chauffeur"}
                        value={values.chauffeur}>
                        {({form}) => (
                            <FormControl fullWidth error={Boolean(errors.chauffeur)}>
                                <InputLabel id="label-client">Sélectionner le chauffeur :</InputLabel>
                                <Select
                                    labelId="label-chauffeur"
                                    label="Sélectionner le chauffeur :"
                                    variant="outlined"
                                    name="chauffeur"
                                    value={values.chauffeur}
                                    onChange={(e) => {
                                        form.setFieldValue(
                                            "chauffeur",
                                            e.target.value
                                        );
                                    }}
                                >
                                    {operateurs && operateurs.map((o) => {
                                        return (
                                            <MenuItem key={o.id} value={o["@id"]}>
                                                {o.libelle ?? o.email}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {errors.chauffeur &&
                                    String(errors.chauffeur) &&
                                    <FormHelperText error={true}>{errors.chauffeur}</FormHelperText>}
                            </FormControl>
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/* EQUIPIER FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    <Typography>Sélectionner l'équipier (optionnel)</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"equipier"}
                        value={values.equipier}>
                        {({form}) => (
                            <FormControl fullWidth error={Boolean(errors.equipier)}>
                                <InputLabel id="label-client">Sélectionner l'équipier :</InputLabel>
                                <Select
                                    labelId="label-client"
                                    label="Sélectionner l'équipier :"
                                    variant="outlined"
                                    name="equipier"
                                    value={values.equipier || ""}
                                    onChange={(e) => {
                                        form.setFieldValue(
                                            "equipier",
                                            e.target.value
                                        );
                                    }}
                                >
                                    <MenuItem value="">Aucun équipier</MenuItem>
                                    {operateurs && operateurs.map((o) => {
                                        return (
                                            <MenuItem key={o.id} value={o["@id"]}>
                                                {o.libelle ?? o.email}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {errors.equipier &&
                                    String(errors.equipier) &&
                                    <FormHelperText error={true}>{errors.equipier}</FormHelperText>}
                            </FormControl>
                        )}
                    </Field>
                </Grid>
            </Grid>

        </Grid>
    );
}

export default CampaignOperateursField;
