import {quartixConstants} from "./quartix.constants.js";
import {quartixService} from "./quartix.service.js";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";
import {da} from "date-fns/locale";

export const quartixActions = {
    fetchEngineTimes,
    fetchLivePosition,
    fetchTripSummary,
    fetchRoute
};

function fetchEngineTimes(date, vehicleId) {
    return dispatch => {
        dispatch(request());

        quartixService.fetchEngineTimes(date, vehicleId)
            .then(
                data => {
                    dispatch(success(vehicleId, date, data));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: quartixConstants.ENGINE_TIMES_FETCH_REQUEST}
    }

    function success(vehicleId, date, data) {
        return {type: quartixConstants.ENGINE_TIMES_FETCH_SUCCESS, payload: {vehicleId, date, engineTimes: data}}
    }

    function failure(error) {
        return {type: quartixConstants.ENGINE_TIMES_FETCH_FAILURE, error}
    }
}

function fetchLivePosition(vehicleIds) {
    return dispatch => {
        dispatch({ type: quartixConstants.LIVE_POSITION_FETCH_REQUEST });
        quartixService.fetchLivePosition(vehicleIds).then(
            data => dispatch({
                type: quartixConstants.LIVE_POSITION_FETCH_SUCCESS,
                payload: { data }
            }),
            error => dispatch({
                type: quartixConstants.LIVE_POSITION_FETCH_FAILURE,
                error
            })
        );
    };
}

function fetchTripSummary(date, vehicleId) {
    return dispatch => {
        dispatch({ type: quartixConstants.TRIP_SUMMARY_FETCH_REQUEST });
        quartixService.fetchTripSummary(date, vehicleId).then(
            data => dispatch({
                type: quartixConstants.TRIP_SUMMARY_FETCH_SUCCESS,
                payload: { vehicleId, date, data }
            }),
            error => dispatch({
                type: quartixConstants.TRIP_SUMMARY_FETCH_FAILURE,
                error
            })
        );
    };
}

function fetchRoute(date, vehicleId) {
    return dispatch => {
        dispatch({ type: quartixConstants.ROUTE_FETCH_REQUEST });
        quartixService.fetchRoute(date, vehicleId).then(
            data => dispatch({
                type: quartixConstants.ROUTE_FETCH_SUCCESS,
                payload: { vehicleId, date, data }
            }),
            error => dispatch({
                type: quartixConstants.ROUTE_FETCH_FAILURE,
                error
            })
        );
    };
}

