import {
    postHeaders,
} from "../../utils/headers";
import Constants from "../../utils/constants";

export const dashboardService = {
    fetchDashboardData,
    fetchDashboardDataV2

};

function fetchDashboardData(values) {
    const requestOptions = postHeaders(values)

    return fetch(Constants.API_ROUTE_DASHBOARD_DATA, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data
        });
}

function fetchDashboardDataV2(values) {
    const requestOptions = postHeaders(values)

    return fetch(Constants.API_ROUTE_DASHBOARD_DATA_V2, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
