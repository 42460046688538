import React from 'react';
import {Popup} from 'react-leaflet';
import Button from "@mui/material/Button";
import {
    Chip,
    Grid,
    Paper,
    Typography
} from "@mui/material";
import Constants from "../../../utils/constants.js";
import "./ConteneurMarkerPopup.css"
import {helper} from "../../../utils/helper.js";
import BadgeTypeLavage from "../layout/BadgeTypeLavage.js";
import {isExploitant} from "../../../utils/roles.js";
import {useNavigate} from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {synthesesActions} from "../../../modules/syntheses/syntheses.actions.js";
import {useDispatch} from "react-redux";

const ConteneurMarkerPopup = ({conteneur, onClose, onEdit}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [lavageImpossible, setLavageImpossible] = React.useState(!conteneur.lastIntervention?.isAccessible)
    const [hasMaintenance, setHasMaintenance] = React.useState((conteneur.lastIntervention?.isAccessible && conteneur.lastIntervention?.problemes.length > 0))

    const onPDFClick = (interventionId, interventionDate) => {
        dispatch(synthesesActions.fetchInterventionPDF(interventionId, interventionDate))
    }

    return (
        <Popup closeButton={true} className="marker-popup">
            <Paper elevation={0} className={"marker-popup-container"}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"} className={"marker-popup-title"}>
                            {conteneur.nomenclature} : {conteneur.adresse}, {conteneur.commune}
                        </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item container xs={8} sx={{padding: '.5rem'}}>
                            <Grid item xs={12}>
                                <Typography className={"marker-popup-label"}>Type de déchet : </Typography>
                                <Chip size={'small'} label={conteneur.dechetType}
                                      sx={helper.getColorForDechetType(conteneur.dechetType)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={"marker-popup-label"}>Typologie : </Typography>
                                <Chip size={'small'} label={conteneur.conteneurType}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={"marker-popup-label"}>
                                    Nombre
                                    de {helper.getConteneurTypeLibelle(conteneur.conteneurType)} : {conteneur.quantite}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {helper.formatDate(conteneur.lastInterventionDate) !== '' &&
                                    <Typography className={"marker-popup-label"}>
                                        Dernier lavage
                                        : {helper.formatDate(conteneur.lastInterventionDate)} {helper.getFormattedTime(conteneur.lastInterventionDate)}
                                    < /Typography>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={"marker-popup-label"}>
                                    Type de prestation : {conteneur.lastIntervention?.prestation}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={"marker-popup-label"}>
                                    Type de lavage :
                                </Typography>
                                <BadgeTypeLavage type={conteneur.lastIntervention?.typeLavage} sx={{ml: 2}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={"marker-popup-label"}>
                                    Statut :
                                </Typography>
                                <Chip
                                    label={helper.getInterventionStatus(conteneur.lastIntervention)}
                                    sx={{...helper.getInterventionStatusColor(conteneur.lastIntervention), ml: 1}}
                                    size="small"
                                />
                            </Grid>
                            {hasMaintenance &&
                                <Grid item xs={12}>

                                </Grid>
                            }
                            {lavageImpossible &&
                                <Grid item xs={12}>
                                    <Typography className={"marker-popup-label"}>
                                        Raison :
                                    </Typography>
                                    {conteneur.lastIntervention?.problemes && conteneur.lastIntervention?.problemes.map((p, index) => (
                                        <Typography key={index} className={"marker-popup-label"} component={"span"}>
                                            {p.libelle}
                                        </Typography>
                                    ))}

                                </Grid>
                            }
                        </Grid>

                        <Grid item xs={4}>
                            {!lavageImpossible &&
                                <img
                                    src={`${Constants.API_ASSETS_PHOTO_INTERVENTION}/${conteneur?.lastIntervention?.photosApres[0]}`}
                                    alt={"Intervention photo"}
                                    className={"popup-marker-photo"}/>
                            }
                            {lavageImpossible &&
                                <img
                                    src={`${Constants.API_ASSETS_PHOTO_INTERVENTION}/${conteneur?.lastIntervention?.photosInaccessible[0]}`}
                                    alt={"Intervention photo"}
                                    className={"popup-marker-photo"}/>
                            }
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} className={"popup-marker-actions-container"}
                          justifyContent={"space-evenly"}>
                        {isExploitant() &&
                            <Grid item>
                                <Button
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => onEdit(conteneur)}>
                                    Déplacer la borne
                                </Button>
                            </Grid>
                        }
                        <Grid item>
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={() => {
                                    navigate(Constants.APP_ROUTE_INTERVENTIONS.replace(":id", conteneur.id));
                                }}>
                                Détail
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={() => onPDFClick(conteneur.lastIntervention?.id, conteneur.lastIntervention?.dateEvenement)}
                                startIcon={<PictureAsPdfIcon/>}
                                sx={{mt: {xs: 2, sm: 0}}}
                            >
                                Télécharger la fiche PDF
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Paper>
        </Popup>
    );
};

export default ConteneurMarkerPopup;
