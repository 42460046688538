import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {helper} from "../../../utils/helper";
import {dashboardActions} from "../../../modules/dashboard/dashboard.actions";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button, ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Chip, CircularProgress,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    Paper,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import TableHeader from "../layout/table/TableHeader";
import moment from "moment-timezone";
import DashboardLoader from "./DashboardLoader";
import {DatePicker} from "@mui/x-date-pickers";
import Grid from "@mui/material/Grid2";
import {format, parseISO} from "date-fns";
import './DashboardViewV2.css';
import DashboardTable from "./DashboardTable";
import DashboardLastUpdatedTimer from "./DashboardLastUpdatedTimer";
import {
    AssignmentInd,
    FiberManualRecord,
    FireTruck,
    Flag, FormatListBulleted,
    LocalShipping,
    People,
    Replay,
    Tablet
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Constants from "../../../utils/constants.js";
import BadgeTypeLavage from "../layout/BadgeTypeLavage.js";
import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {EngineOnIcon} from "../../../utils/ui/Icons/EngineOnIcon.js";
import {EngineOffIcon} from "../../../utils/ui/Icons/EngineOff.js";
import {quartixActions} from "../../../modules/quartix/quartix.actions.js";
import Loader from "../layout/loading/loader.js";
import DashboardModalDetailsWorkDone from "./DashboardModalDetailsWorkDone.js";

function DashboardView({data, loading, error, dispatch, onModeChange, ...props}) {

    const formatDate = (days) => {
        const date = new Date();
        date.setDate(date.getDate() + days);
        return date.toISOString().split('T')[0]; // Convertit la date en format YYYY-MM-DD
    };

    let currentUser = helper.getCurrentUser();

    const [selectedDate, setSelectedDate] = React.useState(formatDate(0))
    const [selectedTournee, setSelectedTournee] = React.useState(null);
    const [modalOpen, setModalOpen] = React.useState(false);


    const handleSelectDateByDays = (days) => {
        const formattedDate = formatDate(days);
        setSelectedDate(formattedDate);

        let values = {exploitantId: currentUser?.id, date: formattedDate}
        dispatch(dashboardActions.fetchDashboardDataV2(values))
    };

    const handleSelectedDate = (newDate) => {
        const date = moment(newDate).format("YYYY-MM-DD");
        setSelectedDate(date)
        let values = {exploitantId: currentUser?.id, date: date}
        dispatch(dashboardActions.fetchDashboardDataV2(values))
    }

    const loadData = () => {
        if (!props.loading && !props.error) {
            let values = {exploitantId: currentUser?.id, date: selectedDate};
            dispatch(dashboardActions.fetchDashboardDataV2(values));
        }
    };


    useEffect(() => {
        // Fonction pour charger les données
        if (!data) {
            loadData();
        }
        const intervalId = setInterval(loadData, 900000); // 900000 ms = 15 minutes

        // Nettoyage de l'effet
        return () => clearInterval(intervalId);
    }, [loading, error, currentUser, dispatch, dashboardActions]);

    useEffect(() => {
        if (data?.laveuses) {
            data.laveuses.forEach((laveuse) => {
                if (laveuse.quartixId && !props.quartixData?.[laveuse.quartixId]?.[selectedDate]) {
                    dispatch(quartixActions.fetchEngineTimes(selectedDate, laveuse.quartixId));
                }
            });
        }
    }, [data?.laveuses, selectedDate]);


    const getStatutBadge = (data) => {
        if (data && data.hasOwnProperty("totalBacsLaves") && data.hasOwnProperty("totalBacsImpossible") && data.hasOwnProperty("totalALaver")) {
            let total = parseInt(data.totalALaver);
            let lavageImp = parseInt(data.totalBacsImpossible);
            let bacsLaves = parseInt(data.totalBacsLaves);

            if (total <= (bacsLaves + lavageImp)) {
                return <Chip label={"terminé"}
                             sx={{backgroundColor: Constants.COLOR_THEME_GREEN, color: "white", marginRight: "2rem"}}/>
            } else if (bacsLaves > 0 || lavageImp > 0) {
                return <Chip label={"en cours"}
                             sx={{backgroundColor: Constants.COLOR_THEME_BLUE, color: "white", marginRight: "2rem"}}/>
            } else {
                return <Chip label={"Pas démarré"}
                             sx={{backgroundColor: "grey", color: "white", marginRight: "2rem"}}/>
            }
        }
    }

    const getLaveuseData = (laveuseData) => {
        let label = "Pas de véhicule";
        if (laveuseData && laveuseData.description) {
            label = laveuseData.description;
            // if (laveuseData.kilometrageDebut && laveuseData.kilometrageFin) {
            //     label += (laveuseData.kilometrageFin - laveuseData.kilometrageDebut) + " Kms"
            // } else if (laveuseData.kilometrageDebut && !laveuseData.kilometrageFin) {
            //     label += "Km en début de tournée " + laveuseData.kilometrageDebut;
            // } else if (!laveuseData.kilometrageDebut) {
            //     label += "Km non renseigné"
            // }
        }
        return label;
    }

    const getProductiviteColor = (productivite) => {
        if (productivite >= 0 && productivite < 7) return "#FF0000"; // Rouge
        if (productivite >= 7 && productivite < 10) return "#FF8C00"; // Orange foncé
        if (productivite >= 10 && productivite < 13) return "#1976d2"; // Bleu secondaire MUI par défaut
        if (productivite >= 13) return "#D4AF37"; // Or
        return "#D3D3D3"; // Gris clair
    };

    const getDuration = (dateDebut, dateFin) => {
        if (!dateDebut) return "Non démarré";
        if (!dateFin) return "Non terminé";

        const debut = new Date(dateDebut);
        const fin = new Date(dateFin);

        const diffMs = fin - debut;
        const diffMin = Math.floor(diffMs / 60000);

        const hours = Math.floor(diffMin / 60);
        const minutes = diffMin % 60;

        return `Durée totale : ${hours}h${minutes.toString().padStart(2, "0")}`;
    }

    const getLaveuseIconColor = (laveuse) => {
        let problems = laveuse?.problems ?? [];
        if (problems.length === 0) {
            return Constants.COLOR_THEME_GREEN
        }

        let severity = 0;
        for (let p of problems) {
            if (p.importance > severity) {
                severity = p.importance;
            }
        }

        return helper.getLaveuseImportanceColor(severity);
    }

    const getEngineTimeLabel = (laveuse, ignitionType) => {
        if (props.quartixLoading) {
            return <CircularProgress size={16}/>;
        }

        let qdata = props?.quartixData?.[laveuse.quartixId];
        let data = qdata?.[selectedDate]?.[ignitionType === "on" ? 'ignition_on' : 'ignition_off'];

        return data ? helper.getFormattedTime(data) : "-";
    };


    const getKilometers = (laveuse) => {
        if (laveuse.kilometrageDebut && laveuse.kilometrageFin) {
            return laveuse.kilometrageFin - laveuse.kilometrageDebut + " Km"
        }
        return "- Km"
    }

    const handleOpenModal = (tourneeId, date) => {
        setSelectedTournee({tourneeId, date});
        setModalOpen(true);
    };


    return (
        <Grid container>
            <Grid item size={12}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    {/* Bloc gauche : toggle + date picker */}
                    <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <ToggleButtonGroup
                                color="primary"
                                exclusive
                                className="dashboard-button-pickers"
                                size="large"
                                name="bilanType"
                                value={selectedDate}
                                onChange={(event, newValue) => {
                                    handleSelectDateByDays(newValue);
                                }}
                                aria-label="Date"
                            >
                                <ToggleButton
                                    value={-1}
                                    className={selectedDate === formatDate(-1) ? "toggle-button button-selected-green" : "toggle-button"}
                                >
                                    Hier
                                </ToggleButton>
                                <ToggleButton
                                    value={0}
                                    className={selectedDate === formatDate(0) ? "toggle-button button-selected-green" : "toggle-button"}
                                >
                                    Aujourd'hui
                                </ToggleButton>
                                <ToggleButton
                                    value={+1}
                                    className={selectedDate === formatDate(+1) ? "toggle-button button-selected-green" : "toggle-button"}
                                >
                                    Demain
                                </ToggleButton>
                            </ToggleButtonGroup>

                            <DatePicker
                                value={parseISO(selectedDate)}
                                displayWeekNumber={true}
                                onChange={(value) => handleSelectedDate(value)}
                            />
                        </Stack>
                    </Grid>

                    {/* Bloc droit : reload + boutons de mode */}
                    <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <DashboardLastUpdatedTimer lastUpdated={data?.lastUpdated}/>
                            <Button onClick={loadData}>
                                <Replay/>
                            </Button>
                            <ButtonGroup size="small">
                                <Button
                                    variant="contained"
                                    startIcon={<Tablet/>}
                                />
                                <Button
                                    variant="outlined"
                                    startIcon={<FormatListBulleted/>}
                                    onClick={() => onModeChange("OLD")}
                                />
                            </ButtonGroup>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            {props.loading &&
                <Loader/>
            }
            {!props.loading &&
                <>
                    {/* LAVEUSES */}
                    <Stack sx={{width: "100%", marginTop: "2rem"}} direction={"row"} spacing={1} flexWrap={"wrap"}
                           useFlexGap>
                        {data && data.laveuses && data.laveuses.map((laveuse, id) => {
                            return (<Card sx={{border: `1px solid ${getLaveuseIconColor(laveuse)}`, minWidth: "300px"}}>
                                    <CardHeader
                                        title={<Stack direction={"row"} spacing={2}
                                                      sx={{marginBottom: "1px solid grey"}}
                                                      justifyContent={"space-between"}>
                                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                <LocalShipping
                                                    sx={{fontSize: "24px", color: getLaveuseIconColor(laveuse)}}/>
                                                <Typography variant={"body1"}>{laveuse.description}</Typography>
                                            </Stack>

                                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                <Stack direction={"row"} spacing={1}>
                                                    <People sx={{color: Constants.COLOR_THEME_GREEN}}/>
                                                    <Typography variant={"body1"}>{laveuse.operateur}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>}
                                    />
                                    <CardContent sx={{padding: "0 .25rem 0", paddingBottom: "0.25rem !important"}}>
                                        <Stack direction={"row"}
                                               justifyContent="space-between"
                                               alignItems={"center"}>
                                            <Stack direction={"column"}>
                                                <Stack direction={"row"}
                                                       alignItems={"center"} spacing={2}>
                                                    <EngineOnIcon style={{fontSize: "24px"}}/>
                                                    <Typography variant={"body2"}>
                                                        {getEngineTimeLabel(laveuse, "on")}
                                                    </Typography>
                                                </Stack>
                                            </Stack>


                                            <Stack direction={"column"} sx={{flexGrow: 1}}>
                                                <div className="line"></div>
                                                <Typography variant={"body2"} textAlign={"center"}>
                                                    {getKilometers(laveuse)}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={"column"}>
                                                <Stack direction={"row"}
                                                       alignItems={"center"} spacing={2}>
                                                    <Typography variant={"body2"}>
                                                        {getEngineTimeLabel(laveuse, "off")}
                                                    </Typography>
                                                    <EngineOffIcon style={{fontSize: "24px"}}/>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </CardContent>
                                </Card>

                            )
                        })}
                    </Stack>

                    {/* DASHBOARD (CLIENT) */}
                    <Stack sx={{width: "100%", marginTop: "2rem"}}>
                        {data && data.clients && Object.entries(data?.clients).map(([client, clientData]) => {
                            return (
                                <Accordion key={client} sx={{border: '1px solid lightgray'}} defaultExpanded>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Stack direction="row" spacing={2}
                                               sx={{
                                                   width: "100%",
                                                   justifyContent: "space-between",
                                                   paddingRight: "2rem",
                                                   alignItems: "center"
                                               }}>
                                            <Stack direction="row" spacing={2}>
                                                {getStatutBadge(clientData)}
                                                <Stack direction="row" spacing={0}>
                                                    <AssignmentInd sx={{color: Constants.COLOR_THEME_BLUE}}
                                                                   fontSize={"large"}/>
                                                    <Typography variant={"h5"}>{client}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack spacing={2} direction={"row"}>
                                                <Chip label={clientData.totalTours + " tournée(s)"}/>
                                                <Chip label={clientData.totalALaver + " bacs"}
                                                      sx={{
                                                          backgroundColor: Constants.COLOR_THEME_BLUE,
                                                          color: "white"
                                                      }}/>
                                                <Chip
                                                    label={clientData.totalBacsLaves + " lavé(s)"}
                                                    sx={{
                                                        backgroundColor: Constants.COLOR_THEME_GREEN,
                                                        color: "white"
                                                    }}/>

                                                {clientData.totalBacsImpossible > 0 &&
                                                    <Chip
                                                        label={clientData.totalBacsImpossible + " lavage(s) impossible(s)"}
                                                        sx={{
                                                            backgroundColor: Constants.COLOR_THEME_RED,
                                                            color: "white"
                                                        }}/>}
                                            </Stack>
                                        </Stack>
                                        <LinearProgress
                                            variant="determinate"
                                            value={(clientData.totalBacsLaves / clientData.totalALaver) * 100}
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '4px',
                                                backgroundColor: '#e0e0e0',
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: Constants.COLOR_THEME_GREEN,
                                                }
                                            }}
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {Object.entries(clientData.operateurs).map(([operateur, operateurData]) => (
                                            <Accordion key={operateur} sx={{border: '1px solid lightgray'}}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                    <Stack direction="row" spacing={2}
                                                           sx={{
                                                               width: "100%",
                                                               justifyContent: "space-between",
                                                               paddingRight: "2rem"
                                                           }}>

                                                        <Stack direction="row" spacing={2}>
                                                            <Stack direction="row" spacing={0}
                                                                   justifyContent={"center"}>
                                                                <People sx={{color: Constants.COLOR_THEME_GREEN}}
                                                                        fontSize={"large"}/>
                                                                <Typography
                                                                    variant={"h6"}>{operateur}</Typography>
                                                            </Stack>
                                                            <Stack direction="row" spacing={0}
                                                                   justifyContent={"center"}>
                                                                <LocalShipping sx={{color: Constants.COLOR_THEME_GREEN}}
                                                                               fontSize={"large"}/>
                                                                <Typography
                                                                    variant={"h6"}>{getLaveuseData(operateurData.laveuse)}</Typography>
                                                            </Stack>
                                                        </Stack>

                                                        <Stack spacing={2} direction={"row"}>
                                                            <Chip label={operateurData.totalTours + " tournée(s)"}/>
                                                            <Chip label={operateurData.totalALaver + " bacs"}
                                                                  sx={{
                                                                      backgroundColor: Constants.COLOR_THEME_BLUE,
                                                                      color: "white"
                                                                  }}/>
                                                            <Chip
                                                                label={operateurData.totalBacsLaves + " lavé(s)"}
                                                                sx={{
                                                                    backgroundColor: Constants.COLOR_THEME_GREEN,
                                                                    color: "white"
                                                                }}/>

                                                            {operateurData.totalBacsImpossible > 0 && <Chip
                                                                label={operateurData.totalBacsImpossible + " lavage(s) impossible(s)"}
                                                                sx={{
                                                                    backgroundColor: Constants.COLOR_THEME_RED,
                                                                    color: "white"
                                                                }}/>}

                                                        </Stack>
                                                    </Stack>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        {operateurData.tours && operateurData.tours.map((tourneeData, idx) => {

                                                            return (<Grid size={{xs: 12, lg: 6}} key={idx}>
                                                                <Card sx={{
                                                                    border: '1px solid lightgray',
                                                                    cursor: "pointer"
                                                                }}
                                                                      onClick={() => handleOpenModal(tourneeData.id, selectedDate)}>
                                                                    <CardHeader
                                                                        subheader={tourneeData.libelle + " - " + tourneeData.totalALaver + " bacs"}
                                                                        className={"tourneecard-header"}
                                                                        slotProps={{
                                                                            subheader: {
                                                                                sx: {
                                                                                    color: 'white',
                                                                                    textAlign: 'center'
                                                                                }
                                                                            }
                                                                        }}

                                                                    />
                                                                    <CardContent>
                                                                        <Stack direction="column" spacing={1}>
                                                                            <Stack>
                                                                                <Stack direction={"row"} spacing={2}
                                                                                       justifyContent={"center"}>
                                                                                    {getStatutBadge(tourneeData)}
                                                                                    <BadgeTypeLavage
                                                                                        type={tourneeData.type}/>
                                                                                </Stack>
                                                                                <Stack direction={"row"} spacing={2}
                                                                                       sx={{padding: '1rem 3rem'}}
                                                                                       justifyContent={"space-evenly"}>
                                                                                    <CircularProgressbar
                                                                                        value={Math.round((tourneeData.totalBacsLaves / tourneeData.totalALaver) * 100)}
                                                                                        text={tourneeData.totalBacsLaves + " bacs lavé(s)"}
                                                                                        styles={buildStyles({
                                                                                            textSize: '12px',
                                                                                            textColor: Constants.COLOR_THEME_GREEN,
                                                                                            pathColor: Constants.COLOR_THEME_GREEN
                                                                                        })}
                                                                                    />
                                                                                    <CircularProgressbar
                                                                                        value={Math.round((tourneeData.totalBacsImpossible / tourneeData.totalALaver) * 100)}
                                                                                        text={tourneeData.totalBacsImpossible + " impossible(s)"}
                                                                                        styles={buildStyles({
                                                                                            textSize: '12px',
                                                                                            textColor: Constants.COLOR_THEME_RED,
                                                                                            pathColor: Constants.COLOR_THEME_RED
                                                                                        })}
                                                                                    />
                                                                                    <CircularProgressbar
                                                                                        value={tourneeData.productivite * 10}
                                                                                        text={tourneeData.productivite + " Bacs/h"}
                                                                                        styles={buildStyles({
                                                                                            textSize: '12px',
                                                                                            textColor: getProductiviteColor(tourneeData.productivite),
                                                                                            pathColor: getProductiviteColor(tourneeData.productivite)
                                                                                        })}
                                                                                    />
                                                                                </Stack>
                                                                                <Stack direction={"row"} spacing={2}
                                                                                       justifyContent="space-between"
                                                                                       alignItems={"center"}>
                                                                                    <Stack direction={"row"}
                                                                                           alignItems={"center"}>
                                                                                        <FiberManualRecord/>
                                                                                        <Typography variant={"body1"}>
                                                                                            {helper.getFormattedTime(tourneeData.dateDebut)}
                                                                                        </Typography>
                                                                                    </Stack>
                                                                                    <div className="line"></div>
                                                                                    <Stack direction={"row"}
                                                                                           alignItems={"center"}>
                                                                                        <Typography variant={"body1"}>
                                                                                            {helper.getFormattedTime(tourneeData.dateFin)}
                                                                                        </Typography>
                                                                                        <Flag/>
                                                                                    </Stack>
                                                                                </Stack>
                                                                            </Stack>
                                                                            <Stack direction={"row"}
                                                                                   justifyContent={"center"}>
                                                                                <Typography variant={"body1"}>
                                                                                    {getDuration(tourneeData.dateDebut, tourneeData.dateFin)}
                                                                                </Typography>
                                                                            </Stack>
                                                                        </Stack>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>)
                                                        })}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>))}
                                    </AccordionDetails>
                                </Accordion>)
                        })}
                    </Stack>
                </>
            }

            {modalOpen && selectedTournee && (
                <DashboardModalDetailsWorkDone
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    tourneeId={selectedTournee.tourneeId}
                    date={selectedTournee.date}
                />
            )}

        </Grid>)
}


const mapStateToProps = function (state) {
    return {
        data: state.dashboard.datav2,
        loading: state.dashboard.loading,
        quartixData: state.quartix.data,
        quartixLoading: state.quartix.loading,
        error: state.dashboard.error
    }
}

export default connect(mapStateToProps)(DashboardView);
