import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {helper} from "../../../utils/helper";
import {dashboardActions} from "../../../modules/dashboard/dashboard.actions";
import {
    Button, ButtonGroup, Stack,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import Grid from "@mui/material/Grid2"
import moment from "moment-timezone";
import DashboardLoader from "./DashboardLoader";
import {DatePicker} from "@mui/x-date-pickers";
import {parseISO} from "date-fns";
import './DashboardView.css';
import DashboardTable from "./DashboardTable";
import DashboardLastUpdatedTimer from "./DashboardLastUpdatedTimer";
import {FormatListBulleted, Replay, Tablet} from "@mui/icons-material";

function DashboardView({data, loading, error, dispatch, onModeChange,...props}) {

    const formatDate = (days) => {
        const date = new Date();
        date.setDate(date.getDate() + days);
        return date.toISOString().split('T')[0]; // Convertit la date en format YYYY-MM-DD
    };

    let currentUser = helper.getCurrentUser();

    const [selectedDate, setSelectedDate] = React.useState(formatDate(0))


    const handleSelectDateByDays = (days) => {
        const formattedDate = formatDate(days);
        setSelectedDate(formattedDate);

        let values = {exploitantId: currentUser?.id, date: formattedDate}
        dispatch(dashboardActions.fetchDashboardData(values))
    };

    const handleSelectedDate = (newDate) => {
        const date = moment(newDate).format("YYYY-MM-DD");
        setSelectedDate(date)
        let values = {exploitantId: currentUser?.id, date: date}
        dispatch(dashboardActions.fetchDashboardData(values))
    }

    const loadData = () => {
        if (!loading && !error) { // Retire la vérification de 'data'
            let values = {exploitantId: currentUser?.id, date: selectedDate};
            dispatch(dashboardActions.fetchDashboardData(values));
        }
    };


    useEffect(() => {
        // Fonction pour charger les données
        if (!data) {
            loadData();
        }
        const intervalId = setInterval(loadData, 900000); // 900000 ms = 15 minutes

        // Nettoyage de l'effet
        return () => clearInterval(intervalId);
    }, [loading, error, currentUser, dispatch, dashboardActions]);

    return (
        <Grid container>
            <Grid item size={12}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    {/* Bloc gauche : toggle + date picker */}
                    <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <ToggleButtonGroup
                                color="primary"
                                exclusive
                                className="dashboard-button-pickers"
                                size="large"
                                name="bilanType"
                                value={selectedDate}
                                onChange={(event, newValue) => {
                                    handleSelectDateByDays(newValue);
                                }}
                                aria-label="Date"
                            >
                                <ToggleButton
                                    value={-1}
                                    className={selectedDate === formatDate(-1) ? "toggle-button button-selected-green" : "toggle-button"}
                                >
                                    Hier
                                </ToggleButton>
                                <ToggleButton
                                    value={0}
                                    className={selectedDate === formatDate(0) ? "toggle-button button-selected-green" : "toggle-button"}
                                >
                                    Aujourd'hui
                                </ToggleButton>
                                <ToggleButton
                                    value={+1}
                                    className={selectedDate === formatDate(+1) ? "toggle-button button-selected-green" : "toggle-button"}
                                >
                                    Demain
                                </ToggleButton>
                            </ToggleButtonGroup>

                            <DatePicker
                                value={parseISO(selectedDate)}
                                displayWeekNumber={true}
                                onChange={(value) => handleSelectedDate(value)}
                            />
                        </Stack>
                    </Grid>

                    {/* Bloc droit : reload + boutons de mode */}
                    <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <DashboardLastUpdatedTimer lastUpdated={data?.lastUpdated} />
                            <Button onClick={loadData}>
                                <Replay />
                            </Button>
                            <ButtonGroup size="small">
                                <Button
                                    variant="outlined"
                                    startIcon={<Tablet />}
                                    onClick={() => onModeChange("NEW")}
                                />
                                <Button
                                    variant="contained"
                                    startIcon={<FormatListBulleted />}
                                />
                            </ButtonGroup>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>


            <Grid item xs={12}>
                <DashboardTable data={data} loading={loading}/>
            </Grid>

        </Grid>
    )
}


const mapStateToProps = function (state) {
    return {
        data: state.dashboard.data,
        loading: state.dashboard.loading,
        error: state.dashboard.error
    }
}

export default connect(mapStateToProps)(DashboardView);
