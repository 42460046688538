import * as React from 'react';
import {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    CircularProgress,
    CssBaseline,
    Paper,
    TextField,
    Typography,
    Container,
    Grid,
    Button,
    InputAdornment, IconButton
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {connect} from "react-redux";
import {authActions} from "../../modules/authentication/auth.actions";
import {NavLink, useNavigate} from "react-router-dom";
import Constants from "../../utils/constants";
import './LoginPage.css'
import {Visibility, VisibilityOff} from "@mui/icons-material";

let useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: "8rem",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: "1rem",
    },
    submit: {
        margin: "3rem 0 2rem",
    },
}));

function Login({dispatch, ...props}) {
    const [isSubmitting, setIsSubmitting] = React.useState()
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (props.loggedIn) {
            // If already logged in, redirect to home
            navigate(Constants.APP_ROUTE_HOME);
        }
    }, [navigate, props.loggedIn])


    const classes = useStyles();
    const {register, handleSubmit, errors} = useForm();

    const onSubmit = data => {
        setIsSubmitting(true);
        dispatch(authActions.login(data.email, data.password))
    }

    return (
        <Container component="main" maxWidth="xs" className={"loginContainer"}
                   sx={{backgroundImage: `url(/images/laveuse_background.webp)`}}>
            <CssBaseline/>
            <Paper elevation={24} className={"loginFormContainer"}>
                <Typography component="h1" variant="h4" sx={{textAlign: 'center'}}>
                    Solution métier
                </Typography>
                {/*<div className="logo_eugene"></div>*/}
                <div className={"logoWrapper mt-1"}>
                    <img src={`/images/logo.png`} alt="Logo Les Eugene"/>
                </div>


                <Typography component="h2" variant="h5" sx={{textAlign: 'center', marginTop: '3rem'}}>
                    Connexion
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item md={1}></Grid>
                        <Grid item xs={12} md={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                label="Email *"
                                autoFocus
                                autoComplete={"username"}
                                {...register("email", {required: true})}
                            />
                            {errors && errors.email && <span className="form-error">ce champ est requis</span>}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item md={1}></Grid>
                        <Grid item xs={12} md={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Mot de passe"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                autoComplete={"current-password"}
                                {...register("password", {required: true})}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            {errors && errors.password && <span className="form-error">ce champ est requis</span>}
                        </Grid>
                        <Grid container>
                            <Grid item xs={1}></Grid>
                            <Grid item md={10} alignItems={"end"}>
                                <NavLink to={"/forgotPassword"}>
                                    <Typography color={"secondary"} sx={{textAlign: "right"}}>Mot de passe oublié
                                        ?</Typography>
                                </NavLink>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={"mt-2"}>
                        <Grid item md={1}></Grid>
                        <Grid item xs={12} md={10}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disabled={isSubmitting && props.error === false}
                                className={classes.submit}>
                                {!(isSubmitting && props.error === false) &&
                                    <Typography sx={{color: 'white', padding: '.5rem'}}>Se connecter</Typography>
                                }
                                {(isSubmitting && props.error === false) &&
                                    <CircularProgress size={40} color={"primary"}/>}
                            </Button>
                        </Grid>
                    </Grid>


                </form>
            </Paper>
        </Container>
    )
}


const mapStateToProps = function (state) {
    return {
        loggedIn: state.authentication.loggedIn,
        error: state.authentication.error,
    }
}

const LoginPage = connect(mapStateToProps)(Login)

export default LoginPage;
